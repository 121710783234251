html {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
  font-size: 10px;
}

body {
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem;
}

html, body, #mainform {
  width: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

#master {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-overflow-scrolling: touch;
  height: auto !important;
}

#master:not(.legacy-page) {
  min-height: 100%;
}

.legacy-page {
  .clearfix:before, .discoverLocations .locationsTeasers:before, .enquireFormContainer .enquireList .enquireListItem:before, .enquireFormContainer .enquireList:before, .howItWorks:before, .boardMembers:before, .searchForms fieldset .row:before, .basicCampaign fieldset .row:before, .clearfix:after, .discoverLocations .locationsTeasers:after, .enquireFormContainer .enquireList .enquireListItem:after, .enquireFormContainer .enquireList:after, .howItWorks:after, .boardMembers:after, .searchForms fieldset .row:after, .basicCampaign fieldset .row:after {
    content: "";
    display: table;
  }

  .clearfix:after, .discoverLocations .locationsTeasers:after, .enquireFormContainer .enquireList .enquireListItem:after, .enquireFormContainer .enquireList:after, .howItWorks:after, .boardMembers:after, .searchForms fieldset .row:after, .basicCampaign fieldset .row:after {
    clear: both;
  }

  .pull-right {
    float: right !important;
  }

  .pull-left {
    float: left !important;
  }

  .hide {
    display: none !important;
  }

  .fade {
    opacity: 0;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .fade.in {
    opacity: 1;
  }

  .show {
    display: block !important;
  }

  .hidden {
    display: none !important;
    visibility: hidden !important;
  }

  .affix {
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .fixed {
    position: fixed;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .static {
    position: static;
  }

  .dtable {
    display: table;
  }

  .table-cell {
    display: table-cell;
  }

  .inline {
    display: inline;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block;
  }

  .t-center, .btn, .lButton, .dButton, .btnDelete, .scfSubmitButton, .paging.center, .contactSubmitBtn {
    text-align: center;
  }

  .t-left {
    text-align: left;
  }

  .t-right, .paging.right {
    text-align: right;
  }

  .caseContentDesc blockquote, .mainHeader, .mainHeaderLogo, .mainHeaderLogo img, .socialMedia li a img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }

  object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, .scfRadioButtonListLabel, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: normal;
    vertical-align: baseline;
    background: transparent;
  }

  a {
    font-size: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td, th {
    padding: 0;
  }

  ol, ul {
    list-style: none;
  }

  textarea {
    outline: none !important;
    -webkit-appearance: none;
    border-radius: 0;
  }

  input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="password"], input[type="submit"], input[type="button"] {
    -webkit-appearance: none;
    border-radius: 0;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1 !important;
  }

  .container, .contentModule, .landingHeader .mainHeaderContact, .guidesContentModules .contentModule, .guidesContentModules .teasersList {
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .container:before, .contentModule:before, .landingHeader .mainHeaderContact:before, .guidesContentModules .contentModule:before, .guidesContentModules .teasersList:before, .container:after, .contentModule:after, .landingHeader .mainHeaderContact:after, .guidesContentModules .contentModule:after, .guidesContentModules .teasersList:after {
    content: "";
    display: table;
  }

  .container:after, .contentModule:after, .landingHeader .mainHeaderContact:after, .guidesContentModules .contentModule:after, .guidesContentModules .teasersList:after {
    clear: both;
  }

  @media screen and (max-width: 955px) {
    .container, .contentModule, .landingHeader .mainHeaderContact, .guidesContentModules .contentModule, .guidesContentModules .teasersList {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .containerSmall, .guideContentModules .contentModule, .guideContentModules .teaser, .guideContentModules .teasersList {
    max-width: 700px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .containerSmall:before, .guideContentModules .contentModule:before, .guideContentModules .teaser:before, .guideContentModules .teasersList:before, .containerSmall:after, .guideContentModules .contentModule:after, .guideContentModules .teaser:after, .guideContentModules .teasersList:after {
    content: "";
    display: table;
  }

  .containerSmall:after, .guideContentModules .contentModule:after, .guideContentModules .teaser:after, .guideContentModules .teasersList:after {
    clear: both;
  }

  @media screen and (max-width: 955px) {
    .containerSmall, .guideContentModules .contentModule, .guideContentModules .teaser, .guideContentModules .teasersList {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .formWrapper {
    max-width: 900px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .formWrapper:before, .formWrapper:after {
    content: "";
    display: table;
  }

  .formWrapper:after {
    clear: both;
  }

  .mainContent {
    position: relative;
    z-index: 5;
    width: 100%;
  }

  /*IO 142 backlog that will move the title downwards.
 - Iphone 5s Portrait and landscape view for IO-142 backlog.
*/
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .mainContent {
      position: relative;
      z-index: 5;
      width: 100%;
    }
  }
  /*IO 142 backlog that will move the title downwards.
 Nexus 7 (portrait and landscape) ----------- */
  @media only screen and (min-width: 600px) and (max-width: 603px) and (orientation: portrait) {
    .mainContent {
      position: relative;
      z-index: 5;
      width: 100%;
    }
  }
  /*IO 142 backlog that will move the title downwards.
 Nexus 4 (portrait and landscape) ----------- */
  @media screen and (device-width: 640px) and (device-height: 384px) and (orientation: portrait) {
    .mainContent {
      position: relative;
      z-index: 5;
      width: 100%;
      margin-top: 50px;
    }
  }
  /*IO 142 backlog that will move the title downwards.
 Nexus 5 (portrait and landscape) ----------- */
  @media screen and (device-width: 640px) and (orientation: landscape) {
    .mainContent {
      position: relative;
      z-index: 5;
      width: 100%;
    }
  }

  .mainContent:before, .mainContent:after {
    content: "";
    display: table;
  }

  .mainContent:after {
    clear: both;
  }

  strong {
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    font-weight: 300;
    color: #1d1d1d;
    margin-bottom: 8px;
    margin-bottom: 0.8rem;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden], template {
    display: none;
  }

  a {
    background: transparent;
  }

  a:active, a:hover {
    outline: 0;
  }

  img {
    border: 0;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  button {
    overflow: visible;
  }

  button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled], html input[disabled] {
    cursor: default;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"], input[type="radio"] {
    padding: 0;
  }

  @font-face {
    font-family: 'bebas_neueregular';
    src: url("../fonts/BebasNeue-webfont.eot");
    src: url("../fonts/BebasNeue-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/BebasNeue-webfont.woff") format("woff"), url("../fonts/BebasNeue-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  a, .link {
    font-weight: 600;
    background: transparent;
    border: 0 solid transparent;
    outline: none;
    color: #3277be;
    text-decoration: none !important;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  a:hover, .link:hover {
    color: #215a95;
  }

  .blockHeader:before, .blockHeader:after {
    content: "";
    display: table;
  }

  .blockHeader:after {
    clear: both;
  }

  .blockHeader h2 {
    font-size: 30px;
    font-size: 3rem;
    color: #1d1d1d;
    font-weight: 300;
    padding: 0 0 10px 0;
    display: inline-block;
    border-bottom: 2px solid #b5d334;
  }

  .blockHeader h3 {
    font-size: 26px;
    font-size: 2.6rem;
    color: #1d1d1d;
    font-weight: 300;
    padding: 0 0 8px 0;
    display: inline-block;
    border-bottom: 2px solid #b5d334;
  }

  .blockHeader h4 {
    font-size: 22px;
    font-size: 2.2rem;
    color: #1d1d1d;
    font-weight: 300;
    padding: 0 0 4px 0;
    display: inline-block;
    border-bottom: 2px solid #b5d334;
  }

  .blockHeader p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    color: #1d1d1d;
  }

  .pageHeader:before, .pageHeader:after {
    content: "";
    display: table;
  }

  .pageHeader:after {
    clear: both;
  }

  .pageHeader h1 {
    font-size: 44px;
    font-size: 4.4rem;
    color: #1d1d1d;
    font-weight: 300;
    padding: 0 0 10px 0;
    display: inline-block;
    border-bottom: 2px solid #b5d334;
    margin: 25px 0;
  }

  @media screen and (max-width: 660px) {
    .blockHeader {
      text-align: center;
    }

    .blockHeader h2 {
      padding: 0 0 2rem 0;
    }
  }

  section.grey {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f4f4f4;
  }

  section.green {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #edf2d6;
  }

  .contentEditable {
    color: #1d1d1d;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    font-weight: 300;
  }

  .contentEditable ul, .contentEditable ol {
    overflow: hidden;
    padding: 14px 15px 10px 23px;
    list-style: disc outside;
  }

  .contentEditable ul li, .contentEditable ol li {
    font-weight: 300;
    display: list-item;
    margin-bottom: 5px;
    color: #1d1d1d;
  }

  .contentEditable ol {
    list-style: decimal outside;
  }

  .contentEditable h2 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 29px;
    line-height: 2.9rem;
    color: #1d1d1d;
    font-weight: 300;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 660px) {
    .pageHeader:before, .pageHeader:after {
      content: "";
      display: table;
    }

    .pageHeader:after {
      clear: both;
    }

    .pageHeader h1 {
      font-size: 27px;
      font-size: 2.7rem;
      line-height: 35px;
      line-height: 3.5rem;
      color: #1d1d1d;
      font-weight: 300;
      padding: 0 0 20px 0;
      display: inline-block;
      border-bottom: 2px solid #b5d334;
      margin: 25px 0;
    }
  }

  .btn, .lButton, .dButton, .btnDelete, .scfSubmitButton, .contactSubmitBtn {
    border: 1px solid transparent;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    padding: 7px 0px;
    cursor: pointer;
    text-decoration: none !important;
    outline: none !important;
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn::-moz-focus-inner, .lButton::-moz-focus-inner, .dButton::-moz-focus-inner, .btnDelete::-moz-focus-inner, .scfSubmitButton::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  .btn.disabled, .disabled.lButton, .disabled.dButton, .disabled.btnDelete, .disabled.scfSubmitButton, .btn:disabled, .lButton:disabled, .dButton:disabled, .mButton:disabled, .btnDelete:disabled, .scfSubmitButton:disabled, .btn[disabled], [disabled].lButton, [disabled].dButton, [disabled].mButton, [disabled].btnDelete, [disabled].scfSubmitButton {
    background: #aeaeae !important;
    color: #eee !important;
    cursor: default !important;
  }

  .lButton, a.lButton {
    background: #b5d334;
    color: #000000 !important;
    font-weight: 600;
  }

  .lButton:hover, a.lButton:hover {
    background: #9cb433;
  }

  .lButton:active, .lButton:focus,
  a.lButton:active, a.lButton:focus {
    background: #cbe269;
  }

  .dButton, a.dButton {
    background: #4D4F53;
    color: #fff !important;
    font-weight: 600;
  }

  .dButton:hover, a.dButton:hover {
    background: #333;
  }

  .dButton:active, .dButton:focus,
  a.dButton:active, a.dButton:focus {
    background: #666;
  }

  .dButton.moreBtn {
    margin-top: 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .dButton.moreBtn:after {
    content: '';
    margin-left: 10px;
    width: 7px;
    height: 11px;
    margin-top: -1px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/wra.png) no-repeat;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  @media screen and (max-width: 660px) {
    .dButton.moreBtn:after {
      display: none;
    }
  }

  .btnDelete {
    color: #1d1d1d;
    border: 1px solid #b5b5b5;
    background: transparent;
    min-width: 40px;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 8px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/deleteEnq.png) no-repeat 8px -17px;
  }

  .btnDelete:hover {
    border: 1px solid #1d1d1d;
    background-color: #1d1d1d;
    color: #fff;
    background-position: 8px 12px;
  }

  @media screen and (max-width: 660px) {
    .btn, .lButton, .dButton, .btnDelete, .scfSubmitButton, .contactSubmitBtn {
      min-width: 10px;
      padding: 12px 10px;
      line-height: 19px;
      line-height: 1.9rem;
    }

    .btnDelete {
      padding-left: 25px;
      background-position: 8px -12px;
    }

    .btnDelete:hover {
      background-position: 8px 17px;
    }
  }

  .breadcrumbs {
    margin-bottom: 0;
  }

  .breadcrumbs:before, .breadcrumbs:after {
    content: "";
    display: table;
  }

  .breadcrumbs:after {
    clear: both;
  }

  .breadcrumbs ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline;
  }

  .breadcrumbs ul li {
    font-size: 14px;
    font-size: 1.4rem;
    display: inline;
  }

  .breadcrumbs ul li:first-child:before {
    content: '';
    display: none;
  }

  .breadcrumbs ul li:before {
    content: '>';
    padding: 0 2px;
    font-weight: 600;
    color: #1d1d1d;
  }

  .breadcrumbs ul li a {
    color: #3277be;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .breadcrumbs ul li a:hover {
    border-bottom: 1px solid #3277be;
  }

  .breadcrumbs span.resultText {
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 300;
    display: inline;
  }

  .selecter {
    display: inline-block;
    width: 150px;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .selecter:focus {
    outline: none;
  }

  .selecter.open .selecter-selected {
    border: 1px solid #b5d334 !important;
    -webkit-box-shadow: inset 0 0 0 1px #b5d334;
    -moz-box-shadow: inset 0 0 0 1px #b5d334;
    box-shadow: inset 0 0 0 1px #b5d334;
  }

  .countryLandingSearch:focus .selecter .selecter-selected {
    border: 1px solid #b5d334 !important;
    -webkit-box-shadow: inset 0 0 0 1px #b5d334;
  }

  .selecter:focus .selecter-selected {
    border: 1px solid #b5d334 !important;
    -webkit-box-shadow: inset 0 0 0 1px #b5d334;
  }

  .selecter.open .selecter-selected:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }

  .selecter * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .selecter-element {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: -1;
  }

  .selecter-element, .selecter-element:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .selecter-selected {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    border: 1px solid #b5b5b5;
    color: #333;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    position: relative;
    background: #fff;
    cursor: pointer;
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 7px 30px 7px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
  }

  .selecter-selected:after {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 50%;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/bda.png) 50% 50% no-repeat;
    margin-top: -7px;
    content: '';
    width: 15px;
    height: 14px;
  }

  .selecter-options {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    max-height: 260px;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #cccccc;
    border-width: 0 1px 1px;
    background-color: #ffffff;
    display: none;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
    z-index: 50;
    width: 100% !important;
  }

  .selecter-options.scroller {
    position: absolute;
  }

  .selecter-group {
    border-bottom: 1px solid #cccccc;
    color: #999999;
    display: block;
    font-size: 11px;
    padding: 10px 15px;
    text-transform: uppercase;
  }

  .selecter-item {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    border-bottom: 1px solid #b5b5b5;
    color: #333;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 100%;
    background: transparent;
    cursor: pointer;
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 7px 10px;
    text-decoration: none;
    text-overflow: ellipsis;
  }

  .selecter-item.placeholder {
    display: none;
  }

  .selecter-item.selected {
    background: #eeeeee;
  }

  .selecter-item.disabled {
    color: #999999;
    cursor: default;
  }

  .selecter-item:last-child {
    border-bottom: 0;
  }

  .selecter-item.disabled {
    color: #cccccc;
    background-color: #ffffff;
  }

  .selecter.open {
    z-index: 3;
  }

  .selecter.open .selecter-selected {
    z-index: 51;
  }

  .selecter.open .selecter-selected, .selecter.focus .selecter-selected {
    background-color: #ffffff;
  }

  .selecter.cover .selecter-options {
    border-width: 1px;
    top: 0;
  }

  .selecter.cover.open .selecter-selected {
    z-index: 49;
  }

  .selecter.bottom .selecter-options {
    top: auto;
    bottom: 100%;
    border-width: 1px 1px 0;
  }

  .selecter.bottom .selecter-item:last-child {
    border: none;
  }

  .selecter.bottom .cover .selecter-options {
    top: auto;
    bottom: 0;
  }

  .selecter.multiple .selecter-options {
    width: 100%;
    position: static;
    border-width: 1px;
    display: block;
  }

  .selecter.disabled .selecter-selected {
    background: #ffffff;
    border-color: #cccccc;
    color: #cccccc;
    cursor: default;
  }

  .selecter.disabled .selecter-options {
    background: #ffffff;
    border-color: #cccccc;
  }

  .selecter.disabled .selecter-group, .selecter.disabled .selecter-item {
    border-color: #cccccc;
    color: #cccccc;
    cursor: default;
  }

  .selecter.disabled .selecter-item.selected {
    background: #fafafa;
  }

  .selecter.disabled .selecter-item {
    color: #cccccc;
    background-color: #ffffff;
  }

  .selecter-options.scroller {
    overflow: hidden;
  }

  .selecter-options.scroller .scroller-content {
    max-height: 260px;
    padding: 0;
  }

  input[type="checkbox"].customCB, .searchFilterAmenities input[type="checkbox"] {
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    font-size: 0;
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .customCBLabel {
    font-size: 13px;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: inline-block;
    zoom: 1 \9;
    display: inline \9;
    line-height: 20px;
  }

  .customCBLabel:before {
    font-size: 17px;
    font-size: 1.7rem;
    -webkit-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    background: #fff url(https://content.instantoffices.com/Prod/images/legacyImages/check.png) 50% -40px no-repeat;
    text-align: center;
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border: 1px solid #b5b5b5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
    padding-top: 1px;
  }

  div input[type="checkbox"].customCB:checked ~ label:before, div input[type="checkbox"].customCB:checked ~ .scfRadioButtonListLabel:before, .searchFilterAmenities input[type="checkbox"][checked] ~ label:before, .searchFilterAmenities input[type="checkbox"][checked] ~ .scfRadioButtonListLabel:before {
    background-position: 50%;
  }

  input[type="radio"].customRB {
    display: none;
  }

  .customRBLabel {
    font-size: 13px;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    height: 20px;
    display: inline-block;
    line-height: 20px;
  }

  .customRBLabel:before {
    font-size: 12px;
    font-size: 1.2rem;
    -webkit-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/radio.png);
    text-align: center;
    content: '';
    color: transparent;
    color: #ffffff \9;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border: 1px solid #b5b5b5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
    padding-top: 3px;
    padding-right: 1px;
  }

  input[type="radio"].customRB:checked + * + label:before, input[type="radio"].customRB:checked + * + .scfRadioButtonListLabel:before, input[type="radio"].customRB.checked + * + label:before input[type="radio"].customRB:checked + label:before, input[type="radio"].customRB.checked + * + .scfRadioButtonListLabel:before input[type="radio"].customRB:checked + label:before, input[type="radio"].customRB.checked + * + label:before input[type="radio"].customRB:checked + .scfRadioButtonListLabel:before, input[type="radio"].customRB.checked + * + .scfRadioButtonListLabel:before input[type="radio"].customRB:checked + .scfRadioButtonListLabel:before, input[type="radio"].customRB.checked + label:before, input[type="radio"].customRB.checked + .scfRadioButtonListLabel:before {
    color: #b5d334;
  }

  label, .scfRadioButtonListLabel {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    font-weight: 300;
    color: #333;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .scfForm label span, .scfRadioButtonListLabel span {
    color: #ca4242;
  }

  input::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="password"] {
    background: #fff;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #b5b5b5;
    max-width: 100%;
    padding: 7px 10px;
    outline: none !important;
  }

  input[type="text"]:focus, input[type="email"]:focus, input[type="tel"]:focus, input[type="search"]:focus, input[type="password"]:focus {
    border: 1px solid #b5d334 !important;
    -webkit-box-shadow: inset 0 0 0 1px #b5d334;
    -moz-box-shadow: inset 0 0 0 1px #b5d334;
    box-shadow: inset 0 0 0 1px #b5d334;
  }

  input[type="text"].validate, input[type="email"].validate, input[type="tel"].validate, input[type="search"].validate, input[type="password"].validate {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #ca4242;
  }

  input[type="text"].validate:focus, input[type="email"].validate:focus, input[type="tel"].validate:focus, input[type="search"].validate:focus, input[type="password"].validate:focus {
    -webkit-box-shadow: inset 0 0 0 1px #ca4242;
    -moz-box-shadow: inset 0 0 0 1px #ca4242;
    box-shadow: inset 0 0 0 1px #ca4242;
    border: 1px solid #ca4242 !important;
  }

  .selecter.validate .selecter-selected {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #ca4242;
  }

  textarea {
    min-height: 50px;
    background: #fff;
    -webkit-transition: border 300ms ease-in-out, box-shadow 300ms ease-in-out;
    -o-transition: border 300ms ease-in-out, box-shadow 300ms ease-in-out;
    transition: border 300ms ease-in-out, box-shadow 300ms ease-in-out;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #b5b5b5;
    max-width: 100%;
    padding: 7px 10px;
    outline: none !important;
  }

  textarea:focus {
    border: 1px solid #b5d334 !important;
    -webkit-box-shadow: inset 0 0 0 1px #b5d334;
    -moz-box-shadow: inset 0 0 0 1px #b5d334;
    box-shadow: inset 0 0 0 1px #b5d334;
  }

  textarea.validate, textarea.error {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #ca4242;
  }

  .enquirePageForm .row, .scfForm .scfDropListBorder {
    margin-bottom: 15px;
  }

  .enquirePageForm .row.btnRow input {
    width: 100%;
  }

  fieldset .selecter select.validate + .selecter-selected {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #ca4242;
  }

  fieldset .selecter.open select.validate + .selecter-selected, fieldset .selecter.focus select.validate + .selecter-selected {
    -webkit-box-shadow: inset 0 0 0 1px #ca4242;
    -moz-box-shadow: inset 0 0 0 1px #ca4242;
    box-shadow: inset 0 0 0 1px #ca4242;
    border: 1px solid #ca4242 !important;
  }

  .enquirePageForm label, .enquirePageForm .scfRadioButtonListLabel, .scfForm label, .scfForm .scfRadioButtonListLabel {
    margin-bottom: 5px;
  }

  .red {
    color: #bd1313;
  }

  textarea.error {
    border: 0.2rem solid #bd1313;
    color: #1d1d1d;
    padding: 0 0.9rem 0 0.9rem;
  }

  .error, .error p {
    color: #ca4242;
  }

  .scfForm input[type="text"] {
    box-sizing: border-box;
    width: 100%;
  }

  .enquirePageForm textarea, .scfForm textarea {
    min-height: 90px;
    resize: vertical;
    width: 100%;
  }

  .enquirePageForm label, .enquirePageForm .scfRadioButtonListLabel, .scfForm label, .scfForm .scfRadioButtonListLabel {
    display: inline-block;
  }

  .enquirePageForm .row.small input, .enquirePageForm .row.small select, .enquirePageForm .row.small .selecter, .scfForm .row.small input, .scfForm .row.small select, .scfForm .row.small .selecter {
    max-width: 200px;
    max-width: 20rem;
  }

  .enquirePageForm input[type="text"], .enquirePageForm input[type="email"], .enquirePageForm input[type="tel"], .enquirePageForm input[type="search"], .enquirePageForm input[type="password"], .scfForm input[type="text"], .scfForm input[type="email"], .scfForm input[type="tel"], .scfForm input[type="search"], .scfForm input[type="password"] {
    width: 100%;
  }

  .enquirePageForm .selecter, .enquirePageForm select, .scfForm .selecter, .scfForm select {
    width: 100%;
  }

  .enquirePageForm .selecter.small, .scfForm .selecter.small {
    max-width: 19.2rem;
  }

  .scfSubmitButton {
    background: #b5d334;
    color: #000000 !important;
    font-weight: 600;
  }

  .scfSubmitButton:hover {
    background: #9cb433;
  }

  .scfSubmitButton:active {
    background: #cbe269;
  }

  .scfRequired ~ label, .scfRequired ~ .scfRadioButtonListLabel {
    display: none !important;
  }

  .containerForm {
    position: relative;
    margin-top: 40px;
    margin-bottom: 60px;
    border: 1px solid #b5b5b5;
    background: #fff;
    -webkit-box-shadow: 0 0 4px rgba(100, 100, 100, 0.5);
    -moz-box-shadow: 0 0 4px rgba(100, 100, 100, 0.5);
    box-shadow: 0 0 4px rgba(100, 100, 100, 0.5);
    padding: 40px;
  }

  .containerForm:before, .containerForm:after {
    content: "";
    display: table;
  }

  .containerForm:after {
    clear: both;
  }

  .containerForm .leftCol, .containerForm .rightCol {
    width: 50%;
    float: left;
  }

  .containerForm .leftCol {
    padding-right: 40px;
  }

  .containerForm .leftCol:after {
    content: '';
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 50%;
    border-left: 1px solid #b5b5b5;
    z-index: 2;
  }

  .containerForm .rightCol {
    padding-left: 40px;
  }

  .containerForm .formHeader {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #b5b5b5;
  }

  .containerForm .formHeader h1 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 35px;
    line-height: 3.5rem;
    color: #1d1d1d;
    font-weight: 300;
  }

  .containerForm .formHeader h2 {
    margin-top: 10px;
    font-size: 20px;
    font-size: 2rem;
    line-height: 29px;
    line-height: 2.9rem;
    color: #1d1d1d;
    font-weight: 300;
  }

  @media screen and (max-width: 955px) {
    .containerForm {
      padding: 30px;
    }

    .containerForm .leftCol {
      padding-right: 30px;
    }

    .containerForm .rightCol {
      padding-left: 30px;
    }
  }

  @media screen and (max-width: 860px) {
    .containerForm {
      padding: 20px;
    }

    .containerForm .leftCol {
      padding-right: 20px;
    }

    .containerForm .rightCol {
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 660px) {
    .selecter-selected {
      padding: 12px 30px 7px 12px;
      height: 45px;
    }

    input::-moz-focus-inner {
      padding: 0;
      border: 0;
    }

    input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="password"] {
      padding: 12px 10px;
      line-height: 19px;
      line-height: 1.9rem;
    }

    .formWrapper {
      padding: 0;
    }

    .formWrapper .selecter, .formWrapper textarea {
      max-width: 100% !important;
    }

    .formWrapper input[type="text"], .formWrapper input[type="email"], .formWrapper input[type="tel"], .formWrapper input[type="search"], .formWrapper input[type="password"], .formWrapper input[type="submit"], .formWrapper input[type="button"] {
      max-width: 100% !important;
    }

    .containerForm {
      border: 0 solid transparent;
      padding: 10px;
    }

    .containerForm .leftCol {
      width: 100%;
      padding-right: 0;
    }

    .containerForm .leftCol:after {
      border: 0 solid transparent;
      display: none;
    }

    .containerForm .rightCol {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #b5b5b5;
      width: 100%;
      padding-left: 0;
    }
  }

  .scfSectionContent > div {
    margin-bottom: 15px;
    width: 100%;
  }

  .scfSubmitButtonBorder {
    margin-bottom: 15px;
    width: 30%;
  }

  .scfSubmitButtonBorder input, .scfSubmitButtonBorder .contactSubmitBtn {
    width: 100%;
    position: relative;
  }

  .scfValidatorRequired, .scfRequired, .scfValidator {
    padding-left: 10px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #ca4242 !important;
  }

  .scfForm label span:first-child {
    padding-right: 10px;
  }

  .contactFormContainer .scfSectionContent, .contactFormContainer .scfSubmitButtonBorder {
    width: 100%;
  }

  .contactFormContainer .recaptchaContainer {
    padding: 20px 0 30px;
    position: relative
  }

  .contactFormContainer .g-recaptcha > div {
    margin: 0 auto;
  }

  .contactFormContainer .recaptchaContainer .recaptchaErrorMessage {
    font-size: 14px;
    color: #ca4242 !important;
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    display: none;
  }

  .contactFormContainer .recaptchaContainer .recaptchaErrorMessage.recaptchaErrorMessage_shown {
    display: block;
  }

  .mapPropertyCount1,
  .mapPropertyCount2,
  .mapPropertyCount3,
  .mapPropertyCount4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    position: relative;
    z-index: 100;
    color: #fff;
    max-width: 38px;
  }

  /*IO 203, changes for region name background.*/

  .mapCityLabel {
    height: 30px;
    line-height: 29px;
    padding: 0 15px;
    background: #1d1d1d;
  }

  .mapCityLabel a {
    white-space: nowrap;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    border-bottom: 1px solid #fff;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .mapCityLabel a:hover {
    border-bottom: 1px solid transparent;
  }

  .gm-style .gm-iw .gm-photos {
    display: none !important;
  }

  .owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
  }

  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
  }

  .owl-dots {
    margin-top: 10px;
    height: 101px;
    display: none;
  }

  .owl-dots:before, .owl-dots:after {
    content: "";
    display: table;
  }

  .owl-dots:after {
    clear: both;
  }

  .owl-dot {
    display: inline-block;
    margin: 0 5px;
    float: left;
    opacity: 1;
    height: 101px;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .owl-dot.active {
    opacity: 0.5;
  }

  .owl-dots {
    display: none !important;
  }

  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .owl-carousel .owl-animated-in {
    z-index: 0;
  }

  .owl-carousel .owl-animated-out {
    z-index: 1;
  }

  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .owl-height {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
  }

  .owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
  }

  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
  }

  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
  }

  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel .owl-item img {
    display: block;
    height: auto;
    width: 100%;
    -webkit-transform-style: preserve-3d;
  }

  .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display: none;
  }

  .owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-loaded {
    display: block;
  }

  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }

  .owl-carousel.owl-hidden {
    opacity: 0;
  }

  .owl-carousel.owl-refresh .owl-item {
    display: none;
  }

  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
  }

  .owl-carousel.owl-rtl {
    direction: rtl;
  }

  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }

  .no-js .owl-carousel {
    display: block;
  }

  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
  }

  .owl-carousel .owl-item img {
    transform-style: preserve-3d;
  }

  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
  }

  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
  }

  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  .propImageContainer {
    overflow: hidden;
  }

  .propItemDescCol {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }

  .propSlider {
    display: block;
    z-index: 0;
    overflow: hidden;
    position: relative;
  }

  .propSlider .owl-lazy {
    opacity: 0;
  }

  .propSlider .owl-stage-outer {
    width: 100%;
    overflow: hidden;
  }

  .propSlider, .propImage {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .propImageContainer {
    width: 25%;
    height: 230px;
    overflow: hidden;
    font-size: 0;
  }

  .propImageContainer a {
    overflow: hidden;
  }

  .propImageContainer img {
    height: auto;
    width: 100%;
  }

  .propImageContainer .smallImg .holder {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
  }

  .propImageContainer .smallImg .holder .imgWrapper {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .propImageContainer .smallImg .holder .imgWrapper span {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .propImageContainer .smallImg .holder .imgWrapper span img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 50%;
  }

  .propDescContainer {
    width: 50%;
    padding: 9px 20px;
  }

  .propKeyInfo {
    margin-top: 5px;
  }

  .propKeyInfo:before, .propKeyInfo:after {
    content: "";
    display: table;
  }

  .propKeyInfo:after {
    clear: both;
  }

  .propKeyInfo ul {
    width: 50%;
    float: left;
    list-style: disc outside;
    padding: 0 16px;
  }

  .propKeyInfo ul:nth-child(2) {
    padding-right: 0;
  }

  .propKeyInfo ul.transport {
    list-style: none;
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }

  .propKeyInfo ul.transport li {
    width: 50%;
    float: left;
    padding-left: 30px;
  }

  .propKeyInfo ul.transport li span {
    font-style: italic;
    clear: left;
    display: block;
    line-height: 10px;
    line-height: 1rem;
  }

  .propKeyInfo ul.transport li span:before, .propKeyInfo ul.transport li span:after {
    content: "";
    display: table;
  }

  .propKeyInfo ul.transport li span:after {
    clear: both;
  }

  .propKeyInfo ul.transport li.tube {
    background: no-repeat 0 5px;
    line-height: 14px;
    line-height: 1.4rem;
  }

  .propKeyInfo ul.transport li.tube span {
    padding-top: 5px;
  }

  .propKeyInfo ul.transport li.rail {
    background: no-repeat 0 5px;
    line-height: 14px;
    line-height: 1.4rem;
  }

  .propKeyInfo ul.transport li.rail span {
    padding-top: 5px;
  }

  .propKeyInfo ul.contacts {
    width: 100%;
    padding: 0;
    list-style: none;
  }

  @media screen and (max-width: 1100px) {
    .propKeyInfo ul.contacts {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .propKeyInfo ul.contacts li {
    line-height: 24px;
    line-height: 2.4rem;
    font-size: 14px;
    font-size: 1.4rem;
  }

  .propKeyInfo ul.contacts label, .propKeyInfo ul.contacts .scfRadioButtonListLabel {
    width: 55px;
    line-height: 19px;
    line-height: 1.9rem;
    display: inline-block;
    vertical-align: middle;
  }

  .propKeyInfo ul.contacts span {
    line-height: 19px;
    line-height: 1.9rem;
    display: inline-block;
    vertical-align: middle;
    color: #1d1d1d;
  }

  .propKeyInfo ul.contacts a {
    line-height: 19px;
    line-height: 1.9rem;
    display: inline-block;
    vertical-align: middle;
  }

  .propKeyInfo ul li {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 22px;
    line-height: 2.2rem;
    color: #333;
    font-weight: 300;
  }

  .propItem {
    border: 1px solid #b5b5b5;
    margin-bottom: 20px;
    position: relative;
    display: flex;
  }

  .propItem:before, .propItem:after {
    content: "";
    display: table;
  }

  .propItem:after {
    clear: both;
  }

  .propItem.propItemFeatured .dotsOuter {
    overflow: hidden;
  }

  .propItem.propItemFeatured .propImageContainer, .propItem.propItemFeatured .propSlider {
    overflow: visible;
    z-index: 5;
  }

  .propItem.propItemFeatured .propSlider.propSliderFeatured {
    padding-bottom: 150%;
  }

  .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-next, .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-prev {
    top: 33%;
  }

  .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-dots {
    display: block !important;
  }

  .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-dots .owl-dot {
    display: inline-block;
    width: 101px;
    height: 101px;
    background-size: cover !important;
  }

  .propItem.propItemFeatured .propKeyInfo {
    margin-top: 10px;
  }

  .propSlider {
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
  }

  .propItem .propImageContainer {
    height: auto;
  }

  .propItem .item-link {
    display: inline-block;
    width: 101px;
    height: 101px;
    background-size: cover !important;
  }

  .propItem .owl-next, .propItem .owl-prev {
    cursor: pointer;
    top: 50%;
    margin-top: -20px;
    z-index: 5;
    left: 0;
    position: absolute;
    width: 29px;
    height: 40px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/searchSliderArrows.png) no-repeat;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .propItem .owl-next {
    right: 0;
    left: auto;
    background-position: -40px 0;
  }

  .propSliderItem {
    overflow: hidden;
  }

  .propItemStreet {
    font-size: 20px;
    font-size: 2rem;
    color: #333;
    font-weight: 300;
  }

  .propItemStreet a {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    font-size: 20px;
    font-size: 2rem;
    color: #333;
    font-weight: 300;
    border-bottom: 1px solid transparent;
  }

  .propItemStreet a:hover {
    border-bottom: 1px solid #333;
  }

  .propItemAddress {
    font-size: 14px;
    font-size: 1.4rem;
    color: #333;
    font-weight: 300;
    padding-bottom: 5px;
  }

  .propItemDesc {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 20px;
    line-height: 2rem;
    color: #1d1d1b;
    overflow: hidden;
  }

  .propItemDescCol {
    width: 75%;
    float: left;
    background: #fff;
  }

  .propItemPriceCol {
    width: 25%;
    float: left;
    background: #edf2d6;
    padding: 20px;
  }

  .propItemPriceCol:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: red;
    content: '';
    width: 25%;
    background: #edf2d6;
  }

  .propItemStatusCol {
    width: 250px;
    float: right;
  }

  .propItemStatusCol:before {
    content: '';
    position: absolute;
    border-left: 1px solid #b5b5b5;
    bottom: 0;
    top: 0;
  }

  .propItemStatusTime {
    color: #000;
    font-size: 39px;
    font-weight: 300;
    line-height: 1;
  }

  .propItemStatusTime span {
    font-size: 14px;
    margin-left: 10px;
  }

  .propItemStatusDay {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #000;
    margin-bottom: 5px;
  }

  .propItemStatusContainer {
    border-bottom: 1px solid #d3d3d3;
    padding: 15px;
  }

  .propItemDescContainer {
    padding: 10px 15px;
  }

  .propItemDescContainer fieldset.form {
    min-width: 0;
    max-width: 100%;
  }

  .propItemDescContainer fieldset.form input, .propItemDescContainer fieldset.form label, .propItemDescContainer fieldset.form .scfRadioButtonListLabel, .propItemDescContainer fieldset.form .selecter {
    width: 100%;
    max-width: 100%;
  }

  .propItemDescContainer fieldset.form .row {
    margin-bottom: 10px;
  }

  .propItemDescContainer fieldset.form .row .btn, .propItemDescContainer fieldset.form .row .lButton, .propItemDescContainer fieldset.form .row .dButton, .propItemDescContainer fieldset.form .row .btnDelete, .propItemDescContainer fieldset.form .row .scfSubmitButton {
    padding: 7px 0;
    text-align: center;
  }

  .propItemStatusTitle {
    font-size: 14px;
    color: #1d1d1b;
  }

  .propItemStatusTitle span {
    font-weight: 600;
  }

  .propItemStatusDesc {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
    color: #000;
    margin-bottom: 10px;
  }

  .propItemStatusValue {
    margin-top: 15px;
  }

  .propItemStatusValue .symbol {
    cursor: default;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .propItemStatusValue .symbol:last-child {
    margin-right: 0;
  }

  .propItemStatusValue .symbol-filled {
    background: #b5d334;
  }

  .propItemStatusValue .symbol-empty {
    background: #edede9;
  }

  .propItemStatusText {
    font-size: 14px;
    color: #1d1d1b;
    font-weight: 600;
  }

  .propItemControls {
    position: relative;
    z-index: 2;
  }

  .propItemControls .btn, .propItemControls .lButton, .propItemControls .dButton, .propItemControls .btnDelete, .propItemControls .scfSubmitButton {
    width: 100%;
  }

  .propItemControls .toShortList {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }

  .propPriceContainer {
    padding-bottom: 10px;
    position: relative;
  }

  .propPriceContainer a {
    color: #333;
  }

  .propPriceContainer a:hover {
    text-decoration: underline !important;
  }

  .propPriceTitle {
    font-size: 14px;
    font-size: 1.4rem;
    color: #1d1d1d;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .propPriceTitle strong {
    font-weight: 600;
  }

  .propItemPrice {
    font-size: 44px;
    font-size: 4.4rem;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    color: #1d1d1d;
    font-weight: 300;
    line-height: 1;
    white-space: nowrap;
    opacity: 1;
  }

  .propItemPriceUnits {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    font-size: 12px;
    font-size: 1.2rem;
    color: #1d1d1d;
    width: 65px;
  }

  .propPriceVirtual {
    font-size: 12px;
    font-size: 1.2rem;
    color: #1d1d1d;
    margin-top: 5px;
  }

  .propPriceVirtual strong {
    font-weight: 600;
  }

  @media screen and (max-width: 680px) {
    .propItem.propItemFeatured .dotsOuter {
      overflow: hidden;
    }

    .propItem.propItemFeatured .propImageContainer, .propItem.propItemFeatured .propSlider {
      overflow: visible;
    }

    .propItem.propItemFeatured .propSlider.propSliderFeatured {
      padding-bottom: 100%;
    }

    .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-next, .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-prev {
      top: 50%;
    }

    .propItem.propItemFeatured .propSlider.propSliderFeatured .owl-dots {
      display: none !important;
    }

    .propItem.propItemFeatured .propKeyInfo {
      margin-top: 10px;
    }

    .propItem {
      display: block;
    }
  }

  .tabsContent {
    padding-top: 20px;
    padding-right: 30px;
  }

  .tabsContent .tab-pane {
    display: none;
    position: relative;
  }

  .tabsContent .tab-pane.active {
    display: inline-block;
    width: 100%;
  }

  .tabs {
    overflow: hidden;
    list-style: none;
    margin: -1px 0 0 0;
    padding: 0;
    font-size: 0;
    display: inline-block;
    vertical-align: top;
    height: 56px;
    border-right: 1px solid #b5b5b5;
    border-top: 1px solid #b5b5b5;
  }

  .tabs:before, .tabs:after {
    content: "";
    display: table;
  }

  .tabs:after {
    clear: both;
  }

  .tabs li {
    height: 100%;
    display: block;
    float: left;
    overflow: hidden;
  }

  .tabs li a {
    height: 100%;
    line-height: 55px;
    text-decoration: none !important;
    font-size: 14px;
    color: #1d1d1b;
    font-weight: 400;
    padding: 0 10px 0 15px;
    min-width: 185px;
    border-left: 1px solid #b4b5b1;
    background-color: #f4f4f4;
    display: inline-block;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .tabs li a:hover {
    background-color: #edf2d6;
  }

  .tabs li.active a {
    background-color: #edf2d6;
  }

  .tab-content .tab-pane {
    display: none;
    visibility: hidden;
  }

  .tab-content .active {
    display: block;
    visibility: visible;
  }

  .contactTabsCtrl .tabs li a {
    padding: 0 15px;
    min-width: 100%;
  }

  .widgetTitle {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
    color: #1d1d1d;
    display: block;
  }

  .widgetTitle .exp {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 700;
    width: 15px;
    display: inline-block;
    display: none;
  }

  .searchWidgetExp .widgetTitle {
    cursor: pointer;
  }

  .consultantWidget {
    margin-top: 20px;
    max-width: 100%;
  }

  .consultantWidgetImg {
    display: inline-block;
    vertical-align: top;
    *display: inline;
    *zoom: 1;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid #8E8E8E;
    font-size: 0;
  }

  .consultantWidgetImg img {
    width: inherit;
    height: inherit;
  }

  .consultantWidgetInfo {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
  }

  .consultantWidgetInfo .name {
    font-weight: 600;
    color: #1d1d1d;
    font-size: 14px;
    font-size: 1.4rem;
  }

  .consultantWidgetInfo .mail {
    margin-top: 3px;
  }

  .consultantWidgetInfo .mail a {
    text-decoration: none !important;
    font-weight: 400;
    color: #3277be;
    font-size: 14px;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .consultantWidgetInfo .mail a:hover {
    border-bottom: 1px solid #3277be;
  }

  .consultantWidgetInfo .tel {
    margin-top: 3px;
  }

  .consultantWidgetInfo .tel a {
    font-size: 14px;
    font-weight: 300;
    cursor: default;
    color: #1d1d1d;
    text-decoration: none !important;
  }

  .symbol {
    display: inline-block;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-right: 5px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/rating.png) no-repeat;
  }

  .symbolBig {
    display: inline-block;
    width: 20px;
    height: 20px;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    margin-right: 5px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/ratingBig.png) no-repeat -1px 0;
  }

  .symbol:before, .symbolBig:before {
    content: '';
    width: 5px;
    height: 100%;
    display: inline-block;
  }

  .symbol-empty {
    background-position: -18px 0px;
  }

  .symbolBig.symbol-empty {
    background-position: -25px 0px;
  }

  .ui-autocomplete {
    padding-bottom: 25px !important;
    background: #fff url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white2.png) no-repeat 100% 100% !important;
    position: absolute;
    z-index: 20;
    border: solid 1px #ccc;
  }

  .ui-menu-item {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    border-bottom: 1px solid #b5b5b5;
    color: #333;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 100%;
    background: transparent;
    cursor: pointer;
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 7px 10px;
    text-decoration: none;
    text-overflow: ellipsis;
  }

  .ui-menu-item:hover, .ui-menu-item:focus, .ui-menu-item:active, .ui-menu-item.ui-state-focus {
    background: #b5d334;
  }

  .ui-menu-item a {
    color: #333;
    font-weight: 400;
  }

  .ui-helper-hidden-accessible {
    display: none;
  }

  .enquireForm {
    max-width: 40%;
    padding: 20px 30px;
    background: #fff;
    border: 1px solid #b5b5b5;
    display: inline-block;
    z-index: 10;
    right: 40px;
    margin-top: -275px;
    text-align: left;
    float: right;
  }

  .enquireForm .blockHeader {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #b5b5b5;
  }

  .enquireForm .blockHeader h4 {
    margin-bottom: 10px;
  }

  .enquireForm textarea {
    min-height: 90px;
    resize: vertical;
  }

  .enquireForm input, .enquireForm textarea, .enquireForm .selecter {
    width: 100%;
  }

  .enquireForm .formGroup {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1024px) {
    .enquireForm {
      max-width: 50%;
    }
  }

  @media screen and (max-width: 722px) {
    .enquireForm {
      max-width: 70%;
      margin: 10px 15% 0 15%;
      width: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    .enquireForm {
      padding: 10px;
      max-width: 100%;
      margin: 10px 0 0 0;
    }
  }

  .mSearch {
    background-position: center;
    background-size: cover;
    padding: 100px 0;
    text-align: center;
    width: 100%;
    position: relative;
  }

  .mSearch.t-left .mSearchTitle, .mSearch.t-left .campaign .campaignTitle, .campaign .mSearch.t-left .campaignTitle, .mSearch.t-left .campaignBig .campaignTitle, .campaignBig .mSearch.t-left .campaignTitle, .mSearch.t-left .mSearchText, .mSearch.t-left .campaign .campaignText, .campaign .mSearch.t-left .campaignText, .mSearch.t-left .campaignBig .campaignText, .campaignBig .mSearch.t-left .campaignText {
    text-align: left;
  }

  .mSearch .ie8cover {
    display: none;
  }

  .mBottomSearch {
    padding: 30px 0 15px;
  }

  .mBottomSearch .headlines, .mBottomSearch fieldset {
    width: 50%;
    float: left;
  }

  .mBottomSearch .mSearchTitle, .mBottomSearch .campaign .campaignTitle, .campaign .mBottomSearch .campaignTitle, .mBottomSearch .campaignBig .campaignTitle, .campaignBig .mBottomSearch .campaignTitle {
    margin-bottom: 0;
  }

  .mSearchLabel {
    width: 80px;
    height: 35px;
    line-height: 34px;
    display: block;
    float: left;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #4D4F53;
    position: absolute;
    left: 0;
    top: 0;
  }

  input[type="search"].mSearchInput {
    border: 1px solid #333;
    color: #8e8e8e;
    float: left;
  }

  input[type="search"].mSearchInput.validate {
    -webkit-box-shadow: inset 0 0 0 1px transparent;
    -moz-box-shadow: inset 0 0 0 1px transparent;
    box-shadow: inset 0 0 0 1px transparent;
    border: 1px solid #ca4242;
  }

  .searchValidate {
    margin-left: 80px;
    z-index: 1;
    display: none;
    color: #ca4242;
    font-size: 13px;
  }

  .mSearch .searchValidate {
    left: 100%;
  }

  .campaign .campaignTitle, .campaignBig .campaignTitle, .mSearchText, .campaign .campaignText, .campaignBig .campaignText {
    font-weight: 300;
  }

  .mSearchTitle, .campaign .campaignTitle, .campaignBig .campaignTitle {
    font-size: 44px;
    font-size: 4.4rem;
    margin-bottom: 15px;
  }

  .mSearchTitle strong, .mSearchText strong, .campaign .campaignTitle strong, .campaignBig .campaignTitle strong {
    font-weight: 600;
  }

  .mSearchText, .campaign .campaignText, .campaignBig .campaignText {
    font-size: 17px;
    font-size: 1.7rem;
  }

  fieldset.mSearchFields {
    margin-top: 25px;
    display: inline-block;
    width: 100%;
    max-width: 520px;
    min-width: 100px;
  }

  .mSearchRow {
    margin-bottom: 15px;
    text-align: left;
    position: relative;
  }

  .mSearchRow:before, .mSearchRow:after {
    content: "";
    display: table;
  }

  .mSearchRow:after {
    clear: both;
  }

  .mSearchRow.double .mSearchSet {
    float: left;
    width: 49%;
    margin-right: 2%;
  }

  .mSearchRow.double .mSearchSet:last-child {
    margin-right: 0;
  }

  .mSearchSet {
    position: relative;
  }

  .mSearchSet .mainSearchSubmit {
    width: 100%;
    transition: text-indent .01s, background-position .01s, background-color;
  }

  .mSearchSet .mainSearchSubmit.mainSearchSubmitLoading {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("https://content.instantoffices.com/Prod/images/legacyImages/ajax-loader2.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 70%;
    transition: text-indent .01s, background-position .01s, background-color;
  }

  .mSearchField {
    position: relative;
    width: 100%;
    padding-left: 80px;
    float: left;
  }

  .mSearchField:before, .mSearchField:after {
    content: "";
    display: table;
  }

  .mSearchField:after {
    clear: both;
  }

  .mSearchField select {
    width: 100%;
    padding-left: 8px;
    outline: none !important;
  }

  .mSearchField .selecter {
    width: 100%;
  }

  .mSearchField .selecter .selecter-selected {
    white-space: nowrap;
    height: 35px;
    padding: 7px 30px 7px 12px;
    border: 1px solid #333;
  }

  .mSearchField .mSearchInput {
    width: 100%;
  }

  .mSearchRecent {
    text-align: left;
    color: #333;
    font-size: 14px;
    font-weight: 600;
  }

  .mSearchRecent span, .mSearchRecent a {
    color: #255d95;
    font-size: 14px;
    text-decoration: none !important;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .mSearchRecent span:hover, .mSearchRecent a:hover {
    border-bottom: 1px solid #255d95;
  }

  @media screen and (max-width: 955px) {
    .mBottomSearch .headlines {
      width: 100%;
      padding-bottom: 25px;
    }

    .mBottomSearch fieldset {
      width: 100%;
      float: none;
      text-align: center;
    }

    .mBottomSearch fieldset .mSearchRow {
      width: 70%;
      display: inline-block;
    }
  }

  @media screen and (max-width: 730px) {
    .mBottomSearch fieldset .mSearchRow {
      width: 90%;
    }
  }

  @media screen and (max-width: 660px) {
    .mSearchLabel, .mSearchField .selecter .selecter-selected {
      height: 45px;
    }

    .mSearchField .selecter .selecter-selected {
      padding: 12px 30px 7px 12px;
    }

    .mSearchLabel {
      line-height: 44px;
    }

    .mBottomSearch fieldset .mSearchRow {
      width: 100%;
    }
  }

  .caseStudies {
    padding: 40px 0 50px 0;
  }

  .caseStudies .blockHeader {
    margin-bottom: 30px;
  }

  .caseStudies .caseStudiesTabs {
    margin-top: 30px;
  }

  .caseTabs {
    margin-right: -10px;
    margin-right: -1rem;
    padding-bottom: 10px;
    padding-bottom: 1rem;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .caseTabs:before, .caseTabs:after {
    content: "";
    display: table;
  }

  .caseTabs:after {
    clear: both;
  }

  .caseTabItem {
    width: 20%;
    padding-right: 10px;
    padding-right: 1rem;
    float: left;
    position: relative;
    z-index: 2;
  }

  .caseTabItem:after {
    height: 20px;
    height: 2rem;
    bottom: 0px;
    bottom: 0rem;
    right: 10px;
    right: 1rem;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    content: '';
    background: #b5d334;
    position: absolute;
    z-index: 1;
    left: 0;
  }

  .caseTabItem.active img, .caseTabItem:hover img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
  }

  .caseTabItem.active:after {
    bottom: -10px;
    bottom: -1rem;
  }

  .caseTabItem a {
    width: 100%;
    display: block;
    padding-bottom: 101%;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  .caseTabItem a .caseTabTitle {
    font-weight: 300;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC41Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, rgba(0, 0, 0, 0.5)));
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
    bottom: 0;
    color: #fff;
    padding: 0px 10px 5px 10px;
    padding: 0rem 1rem 0.5rem 1rem;
    position: absolute;
    width: 100%;
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 1;
  }

  .caseTabItem a img {
    min-width: 100%;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
    -webkit-transition: all .6s ease;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .caseContent {
    border: 1px solid #b5b5b5;
    position: relative;
  }

  .caseContent:before, .caseContent:after {
    content: "";
    display: table;
  }

  .caseContent:after {
    clear: both;
  }

  .caseTabContent {
    display: block !important;
  }

  .caseContentDesc {
    width: 78%;
    float: left;
    padding-right: 25px;
    position: relative;
  }

  .caseContentDesc .caseContentImg {
    float: left;
    padding-right: 25px;
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: 38%;
    background-size: cover;
  }

  .caseContentDesc .caseContentImg + blockquote {
    padding-left: 42%;
  }

  .caseContentDesc blockquote {
    padding-left: 25px;
    padding-top: 60px;
    padding-bottom: 25px;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 27px;
    line-height: 2.7rem;
    color: #1d1d1d;
    font-weight: 300;
    position: relative;
  }

  .caseContentDesc blockquote:before {
    content: '';
    width: 18px;
    height: 15px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/quote.png) 50% 50% no-repeat;
    display: block;
    top: 20px;
    position: absolute;
  }

  .caseContentDesc blockquote h4 {
    margin-top: 45px;
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-size: 2.5rem;
    color: #1d1d1d;
    line-height: 1;
  }

  .caseContentDesc blockquote h5 {
    margin-top: 10px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #1d1d1d;
    line-height: 1;
    font-weight: 300;
  }

  .caseContentDetails {
    width: 22%;
    float: right;
    padding-top: 60px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
  }

  .caseContentDetails:after {
    content: '';
    position: absolute;
    right: 22%;
    top: 60px;
    bottom: 25px;
    border-left: 1px solid #b5b5b5;
  }

  .caseTabsScroller {
    display: none;
    position: absolute;
    right: 0;
    width: 10%;
    top: 0;
    bottom: 10px;
    bottom: 1rem;
    background: #ccc url(https://content.instantoffices.com/Prod/images/legacyImages/caseArrow.png) 50% 50% no-repeat;
    z-index: 5;
    cursor: pointer;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  @media screen and (max-width: 1140px) {
    .caseContentDesc .caseContentImg {
      width: 42%;
    }

    .caseContentDesc .caseContentImg + blockquote {
      padding-left: 46%;
    }
  }

  @media screen and (max-width: 970px) {
    .caseTabsScroll {
      overflow: hidden;
      position: relative;
    }

    .caseTabsScroll.scrolled .caseTabs {
      margin-left: -22%;
    }

    .caseTabsScroll.scrolled .caseTabsScroller {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      transform: scaleX(-1);
      -ms-filter: "FlipH";
      filter: FlipH;
    }

    .caseTabsScroller {
      display: block;
    }

    .caseTabs {
      z-index: 4;
      width: 125%;
    }

    .caseTabs .caseTabItem {
      width: 18%;
    }

    .caseContentDesc .caseContentImg {
      width: 46%;
    }

    .caseContentDesc .caseContentImg + blockquote {
      padding-left: 50%;
    }

    .caseContentDesc blockquote {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 24px;
      line-height: 2.4rem;
      padding-top: 40px;
    }

    .caseContentDesc blockquote:before {
      top: 10px;
    }

    .caseContentDesc blockquote h4 {
      margin-top: 10px;
    }

    .caseContentDetails {
      padding-top: 40px;
    }

    .caseContentDetails:after {
      top: 40px;
    }
  }

  @media screen and (max-width: 750px) {
    .caseTabsScroll {
      width: 26%;
      float: left;
      padding-bottom: 87%;
    }

    .caseTabs {
      width: 94%;
      position: absolute;
      bottom: -51%;
    }

    .caseTabs .caseTabItem {
      width: 100%;
      padding-right: 0;
      margin-bottom: 10px;
      margin-bottom: 1rem;
    }

    .caseTabContent {
      float: left;
      width: 74%;
    }

    .caseContentDesc {
      width: 90%;
      padding-top: 78px;
      margin: 0 5%;
    }

    .caseContentDesc blockquote {
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 27px;
      line-height: 2.7rem;
      padding: 5% 0 0 0;
    }

    .caseContentDesc blockquote:before {
      top: -40px;
    }

    .caseContentDesc blockquote h4 {
      margin-top: 40px;
    }

    .caseContentDetails {
      width: 90%;
      padding: 40px 0 0 0;
      margin: 40px 5%;
      border-top: 1px solid #b5b5b5;
    }

    .caseContentDetails:after {
      display: none;
    }

    .caseContentDesc .caseContentImg + blockquote {
      padding-left: 0;
    }

    .caseContentDesc .caseContentImg {
      display: none;
    }

    .caseTabsScroller {
      bottom: 0;
      left: 0;
      right: 0;
      width: 94%;
      top: 89%;
      background: #ccc url(https://content.instantoffices.com/Prod/images/legacyImages/caseArrowD.png) 50% 50% no-repeat;
    }

    .caseTabItem.active:after {
      width: 20px;
      width: 2rem;
      height: 100%;
      top: 0;
      bottom: 0;
      right: -11px;
      right: -1.1rem;
      left: auto;
    }

    .caseTabsScroll.scrolled .caseTabsScroller {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      -ms-transform: scaleY(-1);
      transform: scaleY(-1);
      -ms-filter: "FlipW";
      filter: FlipW;
    }

    .caseTabsScroll.scrolled .caseTabs {
      margin-left: 0;
      bottom: 9%;
    }
  }

  @media screen and (max-width: 675px) {
    .caseTabsScroll {
      display: none;
    }

    .caseTabContent {
      width: 100%;
      padding: 0 15%;
      text-align: center;
    }

    .caseTabContent .caseContentDesc {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .caseTabContent .caseContentDesc blockquote {
      padding-top: 35px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 24px;
      line-height: 2.4rem;
    }

    .caseTabContent .caseContentDesc blockquote:before {
      top: 0;
      left: 50%;
    }

    .caseTabContent .caseContentDesc blockquote h4 {
      margin-top: 20px;
    }

    .caseTabContent .fade {
      opacity: 1 !important;
      display: block !important;
      visibility: visible !important;
    }

    .caseTabContent .tab-content .tab-pane {
      display: block !important;
      visibility: visible !important;
    }

    .caseTabContent .caseContent {
      border: 0 solid transparent;
    }

    .caseTabContent .caseContentDetails {
      display: none;
    }

    .caseTabContent .owl-next, .caseTabContent .owl-prev {
      background: url(https://content.instantoffices.com/Prod/images/legacyImages/caseSlider.png) no-repeat;
      width: 28px;
      height: 49px;
    }

    .caseTabContent .owl-prev {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -25px;
    }

    .caseTabContent .owl-next {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -25px;
      background-position: -37px 0;
    }
  }

  .campaign {
    height: 275px;
  }

  .campaignBig {
    height: 360px;
  }

  .campaign, .campaignBig {
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
  }

  .campaign:before, .campaign:after, .campaignBig:before, .campaignBig:after {
    content: "";
    display: table;
  }

  .campaign:after, .campaignBig:after {
    clear: both;
  }

  .campaign .campaignHeadlinesContainer, .campaignBig .campaignHeadlinesContainer {
    height: 100%;
  }

  .campaign .campaignHeadlinesContainer:before, .campaignBig .campaignHeadlinesContainer:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    margin-left: -15px;
  }

  .campaign .campaignHeadlines, .campaignBig .campaignHeadlines {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    text-align: center;
    padding-left: 15px;
    width: 100%;
  }

  .campaign .campaignTitle, .campaignBig .campaignTitle {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    width: 100%;
  }

  .campaign .campaignText, .campaignBig .campaignText {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .campaign .campaignImage, .campaignBig .campaignImage {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .campaign .container, .campaign .contentModule, .campaign .landingHeader .mainHeaderContact, .landingHeader .campaign .mainHeaderContact, .campaign .guidesContentModules .teasersList, .guidesContentModules .campaign .teasersList, .campaignBig .container, .campaignBig .contentModule, .campaignBig .landingHeader .mainHeaderContact, .landingHeader .campaignBig .mainHeaderContact, .campaignBig .guidesContentModules .teasersList, .guidesContentModules .campaignBig .teasersList {
    position: relative;
    height: 100%;
  }

  .campaign .formLayer, .campaign .mapLayer, .campaignBig .formLayer, .campaignBig .mapLayer {
    height: 100%;
    width: 100%;
    display: none;
    position: absolute;
  }

  .campaign .formLayer.active, .campaign .mapLayer.active, .campaignBig .formLayer.active, .campaignBig .mapLayer.active {
    display: block;
  }

  .campaign .mapLayer, .campaignBig .mapLayer {
    z-index: 3;
  }

  .campaignTitlesContainer {
    height: 100%;
    padding-left: 130px;
    position: relative;
  }

  .campaignTitlesContainer:before {
    height: 100%;
    content: '';
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    margin-left: -20px;
  }

  .campaignTitlesContainer .campaignTitles {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .campaignTitlesContainer .campaignTitles h1, .campaignTitlesContainer .campaignTitles h2 {
    text-align: left;
  }

  .formLayer .campaignTitlesContainer {
    width: 100%;
    padding-right: 400px;
  }

  @media screen and (max-width: 1150px) {
    .formLayer .campaignTitlesContainer {
      padding-left: 40px;
    }
  }

  .propSlider a {
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
  }

  .propSlider a .imgWrapper {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .propSlider a .imgWrapper span {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .propSlider a .imgWrapper span img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 50%;
  }

  .propImage a {
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
    display: block;
  }

  .propImage a .imgWrapper {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .propImage a .imgWrapper span {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .propImage a .imgWrapper span img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 50%;
  }

  .propSlider a {
    display: block !important;
  }

  .caseStude {
    overflow: hidden;
  }

  #ui-datepicker-div {
    -webkit-box-shadow: 0px 0px 2px #888;
    -moz-box-shadow: 0px 0px 2px #888;
    box-shadow: 0px 0px 2px #888;
    background: #fff;
    border: 1px solid #b5b5b5;
    display: none;
    padding: 18px;
    padding: 1.8rem;
  }

  #ui-datepicker-div .ui-datepicker-title {
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
    padding-bottom: 1rem;
  }

  #ui-datepicker-div .ui-datepicker-title .ui-datepicker-month {
    font-weight: 600;
  }

  #ui-datepicker-div .ui-datepicker-title .ui-datepicker-year {
    display: none;
  }

  #ui-datepicker-div .ui-datepicker-header {
    position: relative;
  }

  #ui-datepicker-div .ui-datepicker-header .ui-datepicker-prev span, #ui-datepicker-div .ui-datepicker-header .ui-datepicker-next span {
    top: 2px;
    top: 0.2rem;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/datepick.png) no-repeat;
    position: absolute;
    width: 9px;
    height: 14px;
  }

  #ui-datepicker-div .ui-datepicker-header .ui-datepicker-prev span {
    left: 20px;
    left: 2rem;
    background-position: 0 50%;
  }

  #ui-datepicker-div .ui-datepicker-header .ui-datepicker-next span {
    right: 20px;
    right: 2rem;
    background-position: 100% 50%;
  }

  #ui-datepicker-div th {
    color: #999999;
    height: 28px;
    height: 2.8rem;
    line-height: 28px;
    line-height: 2.8rem;
    width: 28px;
    width: 2.8rem;
  }

  #ui-datepicker-div td {
    border: 1px solid #b5b5b5;
    border-width: 1px;
    border-width: 0.1rem;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
    height: 28px;
    height: 2.8rem;
    line-height: 28px;
    line-height: 2.8rem;
    text-align: center;
    width: 28px;
    width: 2.8rem;
  }

  #ui-datepicker-div td span, #ui-datepicker-div td a {
    display: block;
    font-weight: 600;
    height: 28px;
    height: 2.8rem;
    line-height: 28px;
    line-height: 2.8rem;
    width: 28px;
    width: 2.8rem;
  }

  #ui-datepicker-div td a {
    color: #1d1d1d;
  }

  #ui-datepicker-div td span {
    color: #999999;
  }

  #ui-datepicker-div td .ui-state-hover, #ui-datepicker-div td.ui-datepicker-current-day {
    background: #b5d334;
  }

  .teaserBlock {
    border: 1px solid #b5b5b5;
    padding: 10px;
  }

  .teaserBlock:before, .teaserBlock:after {
    content: "";
    display: table;
  }

  .teaserBlock:after {
    clear: both;
  }

  .teaserBlock .teaserImage {
    width: 40%;
    float: left;
  }

  .teaserBlock .teaserImage img {
    max-width: 100%;
    width: 100%;
    height: auto;
    float: left;
  }

  .teaserBlock .teaserDesc {
    width: 60%;
    float: left;
    padding-left: 15px;
  }

  .teaserBlock .teaserDesc .teaserTitle {
    padding-bottom: 5px;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 400;
  }

  .teaserBlock .teaserDesc .onlineDownload a {
    margin-top: 5px;
    display: inline-block;
    white-space: nowrap;
  }

  .teaserBlock .teaserDesc .onlineDownload a:first-child {
    margin-right: 5px;
  }

  .teasersList .teaserRow {
    margin-bottom: 2%;
    position: relative;
  }

  .teasersList .teaserRow:before, .teasersList .teaserRow:after {
    content: "";
    display: table;
  }

  .teasersList .teaserRow:after {
    clear: both;
  }

  .teasersList .teaserRow .teaserLeft {
    border: 1px solid #b5b5b5;
    width: 49%;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }

  .teasersList .teaserRow .teaserRight {
    border: 1px solid #b5b5b5;
    width: 49%;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }

  .teasersList .teaser {
    width: 49%;
    float: left;
    margin-right: 1%;
    z-index: 3;
  }

  .teasersList .teaser:nth-child(2n) {
    margin-right: 0;
    margin-left: 1%;
  }

  .teasersList .teaser:nth-child(2n):before {
    content: '';
    border: 1px solid #b5b5b5;
    width: 49%;
    right: 0;
    top: 0;
    left: auto;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }

  .teasersList .teaser:before {
    content: '';
    border: 1px solid #b5b5b5;
    width: 49%;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }

  .teasersList .teaserBlock {
    border: 0 solid transparent;
    position: relative;
    z-index: 4;
  }

  @media screen and (max-width: 955px) {
    .teasersList .teaserBlock {
      padding-bottom: 40px;
      position: relative;
    }

    .teasersList .teaserDesc {
      padding-left: 10px;
    }

    .teasersList .onlineDownload {
      position: absolute;
      bottom: 10px;
      left: 10px;
      width: 100%;
    }

    .teasersList .onlineDownload a:first-child {
      margin-right: 10px !important;
    }
  }

  @media screen and (max-width: 820px) {
    .teasersList .teaser {
      padding: 0;
      margin: 20px 0 0 0 !important;
      width: 100%;
      float: none;
      border: 1px solid #b5b5b5;
    }

    .teasersList .teaser:before {
      display: none;
      opacity: 0;
    }

    .teasersList .teaserRow {
      margin: 0;
    }

    .teasersList .teaserLeft, .teasersList .teaserRight {
      display: none;
    }

    .teasersList .teaserBlock {
      padding-bottom: 10px;
    }

    .teasersList .teaserBlock .onlineDownload {
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  @media screen and (max-width: 540px) {
    .teaser .teaserDesc {
      padding: 0;
      width: 100%;
    }

    .teaser .teaserImage {
      width: 100%;
      padding-bottom: 10px;
    }

    .teaser .teaserImage img {
      width: auto;
    }
  }
  /*IO 142, this change will make the icon phone purely black color.*/

  .icon-phone {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/phone.png) no-repeat;
    height: 20px;
    width: 18px;
    margin-bottom: -3px;
  }

  .hmager {
    display: block;
  }

  #master.slick .mainHeader {
    height: 55px;
    padding: 0;
    position: fixed;
    top: 0;
  }

  #master.slick .mainHeader .mainHeaderLogo {
    height: 54px;
    line-height: 54px;
    margin-left: 25px;
  }

  #master.slick .mainHeader .mainHeaderLogo img {
    height: 17px;
    width: auto;
  }

  #master.slick .mainHeader .mainHeaderContact {
    line-height: 48px;
    height: 100%;
  }

  #master {
    -webkit-backface-visibility: hidden;
  }

  .mainHeader {
    -webkit-backface-visibility: hidden;
    -webkit-transition: padding 300ms ease, height 300ms ease;
    -o-transition: padding 300ms ease, height 300ms ease;
    transition: padding 300ms ease, height 300ms ease;
    background: #ffffff;
    width: 100%;
    line-height: 0;
    font-size: 0;
    padding: 20px 0 15px;
    z-index: 26;
    height: 85px;
    position: relative;
  }

  .mainHeader .container, .mainHeader .contentModule, .mainHeader .landingHeader .mainHeaderContact, .landingHeader .mainHeader .mainHeaderContact, .mainHeader .guidesContentModules .teasersList, .guidesContentModules .mainHeader .teasersList {
    position: relative;
  }

  .mainHeaderLogo {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 10000px;
    -webkit-transition: height 200ms ease, line-height 200ms ease, margin-left 200ms ease;
    -o-transition: height 200ms ease, line-height 200ms ease, margin-left 200ms ease;
    transition: height 200ms ease, line-height 200ms ease, margin-left 200ms ease;
    height: 45px;
    line-height: 45px;
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    margin-left: 0;
  }

  .mainHeaderLogo img {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 10000px;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
    width: auto;
    max-width: none \9;
    height: 36px;
    max-height: 100%;
  }

  .mainHeaderContact {
    -webkit-transition: height 200ms ease, line-height 200ms ease;
    -o-transition: height 200ms ease, line-height 200ms ease;
    transition: height 200ms ease, line-height 200ms ease;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 45px;
    line-height: 45px;
    white-space: nowrap;
    z-index: 1;
  }

  .mainHeaderContact .phone {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mainHeaderContact .phone svg {
    stroke: #99ba14;
    display: none;
  }

  .mainHeaderContact .phone a {
    color: #99ba14;
    font-weight: inherit;
    font-size: 22px;
  }

  .mainHeaderContact .phone .phonesWrapper {
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    line-height: 25px;
  }

  .mainHeaderContact .phone .phonesWrapper .phone-local {
    font-size: 18px;
  }

  .landingHeader {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.26);
  }

  .landingHeader .mainHeaderContact {
    text-align: right;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  @media screen and (max-width: 970px) {
    .mainHeaderLogo {
      margin-left: 15px;
    }

    .mainHeaderLogo img {
      height: 26px;
    }

    #master.slick .mainHeaderLogo {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 945px) {
    .mainHeaderLogo {
      width: 200px;
    }

    .mainHeaderContact {
      padding-right: 50px;
    }
  }

  @media screen and (max-width: 870px) {

    .mainHeader {
      border-top: 5px solid #f4f4f4;
      height: 65px;
      padding: 10px 0;
      background: #ffffff;
    }

    .mainHeaderContact, .mainHeaderLogo {
      height: 40px;
      line-height: 40px;
    }

    .mainHeaderContact {
      padding-right: 0;
      padding-left: 100px;
    }
  }

  @media screen and (max-width: 660px) {
    .mSearchRecent {
      display: none;
    }

    .mSearch {
      padding: 1rem 0;
    }

    .howWeCanHelp {
      padding: 2rem 0 13.5rem 0 !important;
      position: relative;
    }

    .howWeCanHelp .moreBtnContainer {
      position: absolute;
      bottom: 60px;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
    }

    .howWeCanHelp ul {
      text-align: center;
    }

    .howWeCanHelp ul li {
      padding-top: 3rem;
    }

    .howWeCanHelp ul li .hwchIcon {
      width: 100% !important;
      float: none !important;
      margin-bottom: 2rem;
    }

    .howWeCanHelp ul li .hwchDesc {
      margin-left: 0 !important;
      width: 100%;
    }

    .howWeCanHelp ul li .hwchDesc h3 {
      margin-bottom: 1rem !important;
    }

    .caseStudies {
      position: relative;
      padding: 2rem 0 13.5rem 0 !important;
    }

    .caseStudies .moreBtnContainer {
      position: absolute;
      bottom: 60px;
      width: 100%;
      left: 0;
      right: 0;
      text-align: center;
    }

    .mainHeader {
      text-align: center;
      padding: 0;
      border-top: 0 solid transparent;
    }

    .mainHeader {
      overflow: hidden;
      height: 5rem;
      left: 0;
      width: 100%;
      -webkit-transition: all 0ms linear;
      -o-transition: all 0ms linear;
      transition: all 0ms linear;
    }

    .mainHeader {
      background: transparent;
      -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.26);
      -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.26);
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.26);
      z-index: 26;
    }

    .mainHeader .container, .mainHeader .contentModule, .mainHeader .landingHeader .mainHeaderContact, .landingHeader .mainHeader .mainHeaderContact, .mainHeader .guidesContentModules .teasersList, .guidesContentModules .mainHeader .teasersList {
      background: #fff;
    }

    .mainHeaderLogo {
      height: auto;
      width: 13.5rem;
      line-height: 5rem;
      display: block;
      text-align: left;
    }

    .mainHeaderLogo img {
      height: auto;
      width: auto;
    }

    /*
IO 142 product backlog :
-Show Header in Mobile, to show color of phone logo. Was changed last 1/27/2017.
*/
    .mainHeaderContact .mainHeaderContact {
      top: 0;
    }

    /*
IO 142 product backlog :
-Show Header in Mobile, to show color of phone logo. Was changed last 1/27/2017.
*/
    .mainHeaderContact .icon-phone {
      background-position: 0 0;
    }

    .mSearchSet {
      width: 100% !important;
      /*margin-bottom: 10px;*/
    }

    .mSearchRow.double .mSearchSet + .mSearchSet {
      margin-top: 15px;
    }

    .mSearchRow {
      /*margin-bottom: 0;*/
    }
  }

  @media screen and (max-width: 970px) {
    .landingFooter .copyright, .landingFooter .footerMenu {
      padding: 0 15px;
    }
  }

  .imgTeaser {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .imgTeaser .txtSection {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .imgTeaser .txtSection .contentEditable {
    color: #fff;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
    line-height: 1.8rem;
  }

  .imgTeaser .body {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .imgTeaser:hover .body {
    height: 100%;
  }

  .imgTeaser:hover .txtSection {
    height: auto !important;
  }

  .colR .imgTeaser:hover .body {
    height: 50%;
  }

  .colR .imgTeaser:hover .txtSection {
    height: auto !important;
  }

  .howWeCanHelp {
    padding: 40px 0 60px 0;
  }

  .howWeCanHelp ul {
    position: relative;
    display: block;
    font-size: 0;
  }

  .howWeCanHelp ul:after {
    position: absolute;
    content: '';
    display: block;
    border-left: 1px solid #b5b5b5;
    left: 50%;
    top: 40px;
    bottom: 0;
    width: 1px;
  }

  @media screen and (max-width: 820px) {
    .howWeCanHelp ul:after {
      display: none;
    }
  }

  .howWeCanHelp ul li {
    padding-top: 40px;
    padding-right: 40px;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    *display: inline;
    *zoom: 1;
  }

  .howWeCanHelp ul li:nth-child(even) {
    padding-left: 40px;
    padding-right: 0;
  }

  @media screen and (max-width: 820px) {
    .howWeCanHelp ul li {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .howWeCanHelp ul li .hwchIcon {
    float: left;
    width: 60px;
  }

  .howWeCanHelp ul li .hwchIcon img {
    max-width: 100%;
    height: auto;
  }

  .howWeCanHelp ul li .hwchDesc {
    margin-left: 80px;
  }

  .howWeCanHelp ul li .hwchDesc h3 {
    color: #1d1d1d;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .howWeCanHelp ul li .hwchDesc h3 a {
    color: #1d1d1d;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .howWeCanHelp ul li .hwchDesc h3 a:hover {
    border-bottom: 1px solid #1d1d1d;
  }

  .discoverLocations {
    background: #f4f4f4;
    border-bottom: 5px solid #fff;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .discoverLocations .locationsTeasers {
    margin-top: 30px;
  }

  .discoverLocations .locationsTeasers .img {
    font-size: 0;
    line-height: 0;
  }

  .discoverLocations .locationsTeasers .img img {
    font-size: 0;
    line-height: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .discoverLocations .locationsTeasers .imgTeaser {
    color: #fff;
  }

  .discoverLocations .locationsTeasers .imgTeaser .block {
    position: relative;
  }

  .discoverLocations .locationsTeasers .imgTeaser .block:hover .body {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC43Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 0%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(0%, rgba(0, 0, 0, 0.7)));
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 0%);
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 0%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 0%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 0%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
  }

  .discoverLocations .locationsTeasers .imgTeaser a {
    color: #fff;
    font-weight: 400;
  }

  .discoverLocations .locationsTeasers .imgTeaser h4 {
    color: #fff;
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1;
  }

  .discoverLocations .locationsTeasers .imgTeaser h5 {
    font-weight: normal;
  }

  .discoverLocations .locationsTeasers .imgTeaser p {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
    line-height: 1.8rem;
    color: #fff;
  }

  .discoverLocations .locationsTeasers .imgTeaser .readMore {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .discoverLocations .locationsTeasers .imgTeaser .txtSection {
    height: 0;
    overflow: hidden;
  }

  .discoverLocations .locationsTeasers .imgTeaser .body {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC43Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.5) 50%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(50%, rgba(0, 0, 0, 0.7)));
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.5) 50%);
    background: -o-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 50%);
    background: -ms-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.7) 50%);
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.7) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
    bottom: 0;
    left: 0;
    padding: 14px;
    padding: 1.4rem;
    position: absolute;
    width: 100%;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .discoverLocations .locationsTeasers .imgTeaser .body strong {
    display: block;
  }

  .discoverLocations .locationsTeasers .imgTeaser .body span {
    display: block;
  }

  .discoverLocations .locationsTeasers .colL {
    float: left;
    margin: 0 0 0 0;
    width: 60%;
  }

  @media screen and (max-width: 955px) {
    .discoverLocations .locationsTeasers .colL {
      width: 45%;
    }
  }

  @media screen and (max-width: 750px) {
    .discoverLocations .locationsTeasers .colL {
      width: 50%;
    }
  }

  @media screen and (max-width: 650px) {
    .discoverLocations .locationsTeasers .colL {
      width: 100%;
    }
  }

  .discoverLocations .locationsTeasers .colL .imgTeaser {
    float: left;
    width: 33.33%;
    padding-right: 1.5%;
  }

  .discoverLocations .locationsTeasers .colL .imgTeaser .cover {
    padding-top: 100%;
  }

  @media screen and (max-width: 955px) {
    .discoverLocations .locationsTeasers .colL .imgTeaser .cover {
      padding-top: 100% !important;
    }
  }

  @media screen and (max-width: 955px) {
    .discoverLocations .locationsTeasers .colL .imgTeaser {
      width: 50% !important;
      padding-right: 2%;
      padding-bottom: 2% !important;
    }
  }

  @media screen and (max-width: 650px) {
    .discoverLocations .locationsTeasers .colL .imgTeaser {
      padding-right: 1%;
    }
  }

  @media screen and (max-width: 650px) {
    .discoverLocations .locationsTeasers .colL .imgTeaser:nth-child(even) {
      padding-right: 0;
      padding-left: 1%;
    }
  }

  .discoverLocations .locationsTeasers .colL .imgTeaser:first-child {
    width: 100%;
    padding-bottom: 1.5%;
  }

  .discoverLocations .locationsTeasers .colL .imgTeaser:first-child .cover {
    padding-top: 34%;
  }

  .discoverLocations .locationsTeasers .colR {
    float: right;
    padding: 0;
    width: 40%;
  }

  @media screen and (max-width: 955px) {
    .discoverLocations .locationsTeasers .colR {
      width: 55%;
    }
  }

  @media screen and (max-width: 750px) {
    .discoverLocations .locationsTeasers .colR {
      width: 50%;
    }
  }

  @media screen and (max-width: 650px) {
    .discoverLocations .locationsTeasers .colR {
      width: 100%;
      padding-bottom: 2%;
    }
  }

  .discoverLocations .locationsTeasers .colR .cover {
    padding-top: 100%;
    padding-bottom: 1px;
  }

  @media screen and (max-width: 955px) {
    .discoverLocations .locationsTeasers .colR .cover {
      padding-top: 80%;
    }
  }

  @media screen and (max-width: 750px) {
    .discoverLocations .locationsTeasers .colR .cover {
      padding-top: 98%;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 650px) {
    .discoverLocations .locationsTeasers .colR .cover {
      padding-top: 100%;
    }
  }

  .discoverLocations .locationsTeasers .colR .imgTeaser {
    padding: 0 0 0 0;
  }

  .discoverLocations .locationsTeasers .colR .imgTeaser h4 {
    font-size: 40px;
    font-size: 4rem;
  }

  .areaGuidesLi.twoColumn .areaGuidesFlyoutList {
    width: 700px;
    margin-left: -350px;
  }

  .areaGuidesLi.twoColumn .areaGuidesFlyoutList li {
    width: 350px;
    float: left;
  }

  .areaGuidesLi.treeColumn {
    position: static !important;
  }

  .areaGuidesLi.treeColumn .areaGuidesFlyoutList {
    width: 100%;
    left: 0;
  }

  .areaGuidesLi.treeColumn .areaGuidesFlyoutList li {
    display: inline-block;
    vertical-align: top;
    *display: inline;
    *zoom: 1;
    min-width: initial;
  }

  .areaGuidesFlyoutList > li:last-child {
    padding-bottom: 25px;
  }

  .areaGuidesLi {
    position: static !important;
  }

  .seeAllLocations {
    background: #f4f4f4;
    position: relative;
    z-index: 7;
  }

  .seeAllLocations:before, .seeAllLocations:after {
    content: "";
    display: table;
  }

  .seeAllLocations:after {
    clear: both;
  }

  .seeAllLocations ul {
    list-style: none;
    margin: 0;
  }

  .seeAllLocations ul > li {
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    *display: inline;
    *zoom: 1;
    margin: 0;
    padding: 15px;
    position: relative;
  }

  .seeAllLocations ul > li:hover {
    background-color: #E9E9EF;
  }

  .seeAllLocations ul > li > span {
    font-weight: 600;
    color: #1d1d1d;
  }

  .seeAllLocations ul > li ul {
    min-width: 100%;
    background: #f4f4f4;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0 ease;
    -o-transition: opacity 0 ease;
    transition: opacity 0 ease;
    padding-top: 5px;
    position: absolute;
    left: -999999px;
    top: -999999px;
    margin-top: 15px;
    cursor: default;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
  }

  .seeAllLocations ul > li ul li {
    width: 33.33%;
    padding: 5px 15px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    float: none;
    min-width: 110px;
    min-width: 11rem;
    cursor: default;
  }

  .seeAllLocations ul > li ul li a {
    white-space: nowrap;
  }

  .seeAllLocations ul > li ul li:hover {
    background-color: #E9E9EF;
  }

  .seeAllLocations ul li.title {
    cursor: default;
  }

  .seeAllLocations ul li:hover ul {
    visibility: visible;
    opacity: 1;
    left: 0;
    top: auto;
    -webkit-transition: opacity 500ms ease;
    -o-transition: opacity 500ms ease;
    transition: opacity 500ms ease;
  }

  .seeAllLocations .areaGuidesFlyoutList p {
    padding-top: 10px;
    line-height: 20px;
    line-height: 2rem;
  }

  @media screen and (max-width: 955px) {
    .discoverLocations .locationsTeasers .imgTeaser .body {
      padding: 10px;
    }

    .discoverLocations .locationsTeasers .imgTeaser .body .txtSection .contentEditable {
      line-height: 15px;
      line-height: 1.5rem;
    }
  }

  @media screen and (max-width: 715px) {
    .imgTeaser:hover .txtSection .contentEditable {
      height: 0;
      padding-bottom: 35%;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 660px) {
    .areaGuidesFlyoutList .column {
      width: 100% !important;
    }

    .imgTeaser:hover .body {
      height: 100%;
    }

    .imgTeaser .readMore {
      margin-bottom: 0;
      margin-top: 8px;
    }

    .seeAllLocations {
      padding: 0;
    }

    .seeAllLocations ul li {
      padding: 0 15px;
      background: transparent;
      display: block;
      border-top: 1px solid #ccc;
      overflow: hidden;
    }

    .seeAllLocations ul li > span.opener {
      display: block;
      padding: 10px 0;
      outline: none;
      position: relative;
    }

    .seeAllLocations ul li > span.opener:before {
      content: '';
      position: absolute;
      display: block;
      background: url(https://content.instantoffices.com/Prod/images/legacyImages/mtabs.png) no-repeat 0 100%;
      width: 25px;
      height: 14px;
      padding: 10px;
      right: 0;
      top: 7px;
    }

    .seeAllLocations ul li:hover {
      background: transparent !important;
    }

    .seeAllLocations ul li.title {
      padding-top: 10px;
      padding-bottom: 10px;
      border: none;
      text-align: center;
    }

    .seeAllLocations ul li ul {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      padding: 0;
      margin: 0;
      overflow: hidden;
      height: 0;
      display: block;
      visibility: visible;
      opacity: 1;
      flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
    }

    .seeAllLocations ul li ul li {
      border: none;
      padding: 0;
    }

    .seeAllLocations ul li ul li a {
      white-space: normal;
    }

    .seeAllLocations ul li.active > span.opener:before {
      background-position: 0 0;
    }

    .seeAllLocations ul li.active ul {
      height: 100%;
      margin-bottom: 10px;
      max-height: 100% !important;
    }
  }

  .enquireFormContainer .enquireList {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .enquireFormContainer .enquireList .enquireListItem {
    padding: 0px 0px 20px 0px;
    padding: 0rem 0rem 2rem 0rem;
  }

  .enquireFormContainer .enquireList .enquireListItem .enquireListImage {
    float: left;
    display: block;
    padding: 0px 16px 0px 0px;
    padding: 0rem 1.6rem 0rem 0rem;
    width: 48%;
  }

  .enquireFormContainer .enquireList .enquireListItem .enquireListDesc {
    float: left;
    width: 52%;
  }

  .enquireFormContainer .enquireList .enquireListItem h3 {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 200;
    margin: 0px 0px 15px 0px;
    margin: 0rem 0rem 1.5rem 0rem;
  }

  .enquireFormContainer .enquireList .enquireListItem h3 a {
    border-bottom: 1px solid transparent;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    color: #1d1d1d;
    font-weight: 200;
  }

  .enquireFormContainer .enquireList .enquireListItem h3 a:hover {
    border-bottom: 1px solid #1d1d1d;
  }

  .enquireFormContainer .enquireList .enquireListItem img {
    width: 100%;
  }

  .enquireFormContainer select {
    height: 35px;
    border: 1px solid #b5b5b5;
    width: 100%;
    max-width: 29.6rem !important;
    font-weight: 400;
    padding: 7px 30px 7px 7px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 19px;
    line-height: 1.9rem;
    color: #333;
  }

  .bigEnquireErr {
    font-size: 14px;
    font-size: 1.4rem;
    color: red;
    padding-bottom: 15px;
    display: block;
  }

  .bigEnquireErr:empty {
    display: none;
  }

  .grayOverlay {
    background: #f4f4f4;
    border-top: 1px solid #b5b5b5;
    width: 100%;
  }

  .grayOverlay:before, .grayOverlay:after {
    content: "";
    display: table;
  }

  .grayOverlay:after {
    clear: both;
  }

  .enquireListItem .enquireListImageOver {
    display: block !important;
    padding-bottom: 100% !important;
    height: 0;
    overflow: hidden;
    position: relative;
  }

  .enquireListItem .enquireListImageOver .imgWrapper {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .enquireListItem .enquireListImageOver .imgWrapper span {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .enquireListItem .enquireListImageOver .imgWrapper span img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 50%;
  }

  .mSearchPpc .ppc-enquiry-form {
    padding-top: 85px;
  }

  @media screen and (max-width: 875px) {
    .ppc-manager__description {
      display: none;
    }
  }

  @media screen and (max-width: 660px) {
    .enquireFormContainer {
      margin-top: 10px;
      margin-bottom: 20px;
      box-shadow: none;
    }

    .enquireFormContainer .formHeader {
      border-bottom: 0 solid transparent;
    }

    .enquireFormContainer .rightCol {
      border-top: 1px solid #b5b5b5;
      padding-top: 20px;
    }

    .enquireFormContainer .enquireList .enquireListItem {
      padding-bottom: 30px;
    }

    .grayOverlay {
      background: transparent;
      border: 0 solid transparent;
    }
  }

  .relatedWidget {
    background: #f4f4f4;
    padding-left: 25px;
    padding-top: 25px;
    padding-bottom: 10px;
    position: relative;
    border-top: 1px solid #b5b5b5;
  }

  .relatedWidget:before, .relatedWidget:after {
    content: "";
    display: table;
  }

  .relatedWidget:after {
    clear: both;
  }

  .relatedWidgetTitle {
    font-size: 20px;
    line-height: 1.2;
    color: #1d1d1d;
    font-weight: 300;
  }

  .relatedWidgetList {
    margin-top: 20px;
  }

  .relatedWidgetList:before, .relatedWidgetList:after {
    content: "";
    display: table;
  }

  .relatedWidgetList:after {
    clear: both;
  }

  .relatedWidgetList.relatedLinks li {
    width: 50%;
  }

  .relatedWidgetList.relatedLinks li:nth-child(2n) {
    padding-right: 0;
  }

  .relatedWidgetList li {
    width: 33.33%;
    float: left;
    margin-bottom: 20px;
    padding-right: 25px;
  }

  .relatedWidgetItemPrice {
    font-weight: 600;
  }

  .relatedWidgetImage {
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
  }

  .relatedWidgetImage a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .relatedWidgetImage img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }

  .relatedWidgetItemTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    color: #1d1d1d;
    margin-top: 10px;
  }

  .relatedWidgetItemDesc {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    font-weight: 400;
    color: #1d1d1d;
    margin-top: 5px;
  }

  .map-bubble-container {
    display: flex;
    background-color: #fff;
    height: 140px;
    justify-content: space-between;
    overflow: hidden;
    width: 521px;
  }

  .mapInfoContainer {
    padding: 16px 20px 11px 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }

  .mapInfoTitle {
    padding-right: 15px;
  }

  .mapInfoTitle a {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    color: #1d1d1d;
  }

  .mapPriceLine {
    padding-top: 10px;
    border-top: solid 1px #eaeaea;
  }

  .mapPriceLine .dButton {
    width: 125px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #99ba14;
  }

  .mapPrice {
    display: flex;
    align-items: center;
  }

  .mapPriceVal {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    color: #1d1d1d;
  }

  .mapPriceValPP {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.29;
    letter-spacing: normal;
    color: #9d9d9d;
    display: inline-block;
    margin-top: -6px;
  }

  .mapPriceLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mapImageContainer {
    max-width: 180px;
    width: 100%;
    overflow: hidden;
  }

  .propertyType {
    font-size: 20px;
    font-size: 2rem;
    color: #1d1d1d;
    line-height: 1.4;
    font-weight: 600;
  }

  .propertyAverageRating {
    margin-top: 10px;
  }

  .propertyPrice {
    font-size: 44px;
    line-height: 38px;
    color: #1d1d1d;
    font-weight: 300;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .propertyPriceContainer {
    margin: 15px 0;
    font-size: 0;
  }

  .propertyPriceUnits {
    margin-left: 15px;
    display: inline-block;
    vertical-align: bottom;
    *display: inline;
    *zoom: 1;
  }

  .propertyPriceUnits div {
    font-size: 12px;
    color: #1d1d1d;
    line-height: 14px;
  }

  .propertyNavIcon {
    background: rgba(0, 0, 0, 0.2) url(https://content.instantoffices.com/Prod/images/legacyImages/propertySliderArrows.png) no-repeat center;
    width: 45px;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .propertyNavIcon:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media screen and (max-width: 400px) {
    .relatedWidgetList.relatedLinks li {
      width: 100%;
    }
  }

  .productImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: 660px) {
    .productImage {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .searchInfoHeader {
    padding: 20px;
  }

  .searchInfoHeader:before, .searchInfoHeader:after {
    content: "";
    display: table;
  }

  .searchInfoHeader:after {
    clear: both;
  }

  .searchFilterWidget {
    padding: 20px 15px 5px;
    border-bottom: 1px solid #b5b5b5;
  }

  .searchFilterWidget:last-child {
    border-bottom: 0 solid;
  }

  .searchFilterWidget .searchValidate {
    position: relative;
    margin-left: 0;
    white-space: normal;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px;
    line-height: 1.8rem;
    height: auto;
    margin-bottom: 10px;
  }

  .searchFilterRow {
    margin-bottom: 15px;
    padding-left: 35px;
    position: relative;
  }

  .searchFilterRow select {
    max-width: 100%;
    height: 35px;
  }

  .searchFilterRow i {
    left: 0;
    position: absolute;
    height: 35px;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    width: 20px;
  }

  .searchFilterRow i.icon-location {
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/schPin.png) 50% no-repeat;
  }

  .searchFilterRow .selecter {
    width: 100%;
    text-align: left;
    vertical-align: middle;
  }

  .searchFilterRow input[type="text"], .searchFilterRow input[type="email"], .searchFilterRow input[type="tel"], .searchFilterRow input[type="search"], .searchFilterRow input[type="password"] {
    width: 100%;
  }

  .searchFilterRowLong {
    margin-bottom: 15px;
    position: relative;
  }

  .searchFilterRowLong .dButton {
    width: 100%;
  }

  .relatedSearches {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .relatedSearches li {
    line-height: 28px;
    line-height: 2.8rem;
  }

  .relatedSearches li a {
    font-size: 14px;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .contactTabsCtrl {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #b5b5b5;
    height: 57px;
  }

  .contactTabsCtrl {
    margin-top: 40px;
  }

  .shortList {
    padding-bottom: 60px;
  }

  .shortList .propItemDescCol {
    width: 58%;
  }

  .shortList .propItemDescCol .propImageContainer {
    width: 34%;
  }

  .shortList .propItemDescCol .propDescContainer {
    width: 66%;
  }

  .shortList .propItemPriceCol {
    width: 20%;
    left: 58%;
  }

  .shortList .propItemPriceCol:before {
    left: 58%;
    width: 20%;
  }

  .shortList .propItemStatusCol {
    width: 22%;
  }

  .shortList .blockHeader {
    margin-bottom: 30px;
  }

  .shortList .date {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 53px;
    line-height: 5.3rem;
    display: inline-block;
    margin-left: 30px;
    font-weight: 300;
  }

  .bubbleClose {
    display: inline-block;
    position: absolute;
    right: 19px;
    top: 19px;
    width: 16px;
    height: 16px;
  }

  .bubbleClose:after,
  .bubbleClose:before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 16px;
    background-color: #808080;
    position: absolute;
    top: 0;
    left: 7px;
  }

  .bubbleClose:before {
    transform: rotate(-45deg);
  }

  .bubbleClose:after {
    transform: rotate(45deg);
  }

  .shortListSubHeader {
    margin-bottom: 30px;
  }

  .shortListSubHeader:before, .shortListSubHeader:after {
    content: "";
    display: table;
  }

  .shortListSubHeader:after {
    clear: both;
  }

  .shortListSubHeader h2 {
    width: 60%;
    float: left;
    font-size: 16px;
    font-size: 1.6rem;
    color: #1d1d1d;
  }

  .shortListSubHeader .shortListShare {
    width: 40%;
    float: left;
    text-align: right;
  }

  .shortListSubHeader .shortListShare:before {
    content: '';
    display: inline-block;
    vertical-align: botom;
    *display: inline;
    *zoom: 1;
    height: 100%;
  }

  .tabsControlLink:before {
    content: '';
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/schTabs.png) no-repeat;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  .tabsControlLink.icon-listCtrl:before {
    background-position: 2px 2px;
  }

  .tabsControlLink.icon-mapCtrl:before {
    background-position: 100%;
  }

  @media screen and (max-width: 1140px) {
    .propItemPriceCol {
      padding: 15px;
    }

    .propDescContainer {
      padding: 9px 15px;
    }
  }

  @media screen and (max-width: 990px) {
    .searchInfoHeader {
      padding: 10px;
    }

    .shortList .propItem {
      margin-bottom: 10px;
    }

    .shortList .propItemDescCol {
      width: 53% !important;
    }

    .shortList .propImageContainer {
      width: 47% !important;
    }

    .shortList .propDescContainer {
      width: 53% !important;
    }

    .shortList .propItemPriceCol {
      width: 23% !important;
    }

    .shortList .propItemPriceCol:before {
      width: 23% !important;
      left: 53% !important;
    }

    .shortList .propItemStatusCol {
      width: 24% !important;
    }
  }

  @media screen and (max-width: 955px) {
    .shortList .propKeyInfo ul {
      width: 100%;
    }

    .shortList .propKeyInfo ul.transport {
      margin-top: 0;
    }

    .shortList .propKeyInfo ul.transport li {
      margin-top: 10px;
      width: 100%;
    }
  }

  @media screen and (max-width: 660px) {
    .shortListSubHeader h2 {
      width: 100% !important;
    }

    .shortList .propItemDescCol {
      width: 100% !important;
    }

    .shortList .propItemDescContainer {
      width: 100% !important;
    }

    .shortList .propDescContainer {
      padding: 20px;
      width: 100% !important;
      text-align: center;
    }

    .shortList .propImageContainer {
      width: 100% !important;
    }

    .shortList .propItemDesc, .shortList .propKeyInfo {
      display: none !important;
    }

    .shortList .propItemPriceCol {
      width: 100% !important;
      left: 0;
    }

    .shortList .propItemPriceCol .propPriceContainer, .shortList .propItemPriceCol .propItemControls {
      width: 100% !important;
    }

    .shortList .propItemPriceCol .propPriceContainer .lButton, .shortList .propItemPriceCol .propItemControls .lButton {
      float: left;
      margin-right: 1%;
      width: 49%;
    }

    .shortList .propItemPriceCol .propPriceContainer .dButton, .shortList .propItemPriceCol .propItemControls .dButton {
      float: left;
      margin-left: 1%;
      width: 49%;
      margin-top: 0;
    }

    .shortList .propItemPriceCol .propPriceContainer {
      padding-right: 0 !important;
    }

    .shortList .propItemPriceCol .propItemControls {
      width: 100% !important;
    }

    .shortList .propItemPriceCol .propItemControls .lButton {
      float: left;
      margin-right: 1%;
      width: 49%;
    }

    .shortList .propItemPriceCol .propItemControls .toShortList {
      margin-bottom: 0;
    }

    .shortList .propItemPriceCol .propItemControls .dButton {
      float: left;
      margin-left: 1%;
      width: 49%;
      margin-top: 0;
    }

    .shortList .propItemPriceCol:before {
      background: transparent !important;
      display: none !important;
      bottom: 0 !important;
      top: 100% !important;
    }

    .shortList .propItemStatusCol {
      width: 100% !important;
    }

    .shortList .propItemStatusCol:before {
      border: 0 solid transparent;
      background: transparent;
      display: none;
    }

    .breadcrumbs, .breadcrumbs ul li {
      font-size: 1.2rem;
    }
  }

  @media screen and (max-width: 640px) {
    .propItemPrice {
      font-size: 38px;
      font-size: 3.8rem;
    }
  }

  @media screen and (max-width: 360px) {
    .propItemPrice {
      font-size: 34px;
      font-size: 3.4rem;
    }
  }

  .propItemStatusCol .step2 {
    display: none;
  }

  .propItemStatusCol .propItemDescContainer .success {
    font-weight: 600;
    margin: 30px 0px 0px 0px;
    margin: 3rem 0rem 0rem 0rem;
  }

  .signInContainer .leftCol {
    padding-right: 40px;
  }

  .signInContainer .leftCol .signInOr {
    position: absolute;
    bottom: 40px;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -20px;
    border: 1px solid #b5b5b5;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    background: #fff;
    color: #1d1d1d;
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 38px;
    line-height: 3.8rem;
    z-index: 3;
  }

  .signInContainer .rightCol {
    padding-left: 40px;
  }

  .signInContainer .rightCol .signUp {
    margin-top: 20%;
    width: 28%;
    min-width: 50px;
  }

  .signInRegister {
    background: #edf2d6;
    padding: 20px 40px;
    margin-top: 30px;
  }

  .signInRegister:before, .signInRegister:after {
    content: "";
    display: table;
  }

  .signInRegister:after {
    clear: both;
  }

  .signInRegister span {
    color: #1d1d1d;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 35px;
    line-height: 3.5rem;
    font-weight: 300;
  }

  .signInForm .fsRow {
    margin-top: 20px;
  }

  .signInForm .fsRow input[type="text"] {
    width: 100%;
  }

  .signInForm .fsRow label, .signInForm .fsRow .scfRadioButtonListLabel {
    display: block;
    margin-bottom: 5px;
  }

  .signInForm .fsRow .customCBLabel {
    margin: 0;
    display: inline-block;
  }

  .signInForm input[type="email"] {
    width: 100%;
  }

  .signInForm input[type="password"] {
    width: 67%;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .signInForm label, .signInForm .scfRadioButtonListLabel {
    margin-bottom: 5px;
  }

  .signInForm .btn, .signInForm .lButton, .signInForm .dButton, .signInForm .btnDelete, .signInForm .scfSubmitButton {
    display: inline-block;
    vertical-align: middle;
    min-width: 50px;
    width: 28%;
    float: right;
  }

  .signInForm .get-password-button {
    width: auto;
  }

  @media screen and (max-width: 660px) {
    .signInContainer .formHeader {
      border-bottom: 0 solid transparent;
    }

    .signInContainer .leftCol {
      position: relative;
      padding-right: 0;
      padding-bottom: 20px;
    }

    .signInContainer .leftCol .formHeader {
      margin-bottom: 10px;
      padding-bottom: 0;
    }

    .signInContainer .leftCol .signInOr {
      top: 100%;
      margin-top: -10px;
    }

    .signInContainer .rightCol {
      padding-left: 0;
      padding-top: 40px;
    }

    .signInContainer .rightCol .signUp {
      margin-top: 0;
      width: 50%;
    }
  }

  .cityLandingFilterWrapper {
    position: absolute;
    width: 100%;
  }

  .cityLandingFilterWrapper .container, .cityLandingFilterWrapper .contentModule, .cityLandingFilterWrapper .landingHeader .mainHeaderContact, .landingHeader .cityLandingFilterWrapper .mainHeaderContact, .cityLandingFilterWrapper .guidesContentModules .teasersList, .guidesContentModules .cityLandingFilterWrapper .teasersList {
    position: relative;
  }

  .cityLandingFilters {
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 10;
    width: 382px;
    background: #fff;
    border: 1px solid #b5b5b5;
  }

  .cityLandingFilters .tabsContent {
    padding: 0;
    min-height: 0;
  }

  .cityLandingFilters .tabs {
    width: 100%;
    border-bottom: 1px solid #b5b5b5;
    border-right: 0 solid transparent;
  }

  .cityLandingFilters .tabsControlItem {
    width: 50%;
  }

  .cityLandingFilters .tabsControlItem:first-child a {
    border-left: 0 solid transparent;
  }

  .cityLandingFilters .tabsControlItem a {
    width: 100%;
  }

  .cityLandingFilters .cityLandingSearch {
    width: 100%;
    padding: 15px 20px;
  }

  .cityLandingFilters .cityLandingSearch .mSearchSet {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .cityLandingFilters .cityLandingSearch .mSearchRow {
    margin: 0;
  }

  .cityLandingTab {
    padding: 15px 20px;
  }

  .campaign .campaignImage {
    background-size: cover;
  }

  #cityMap {
    height: 100%;
    width: 100%;
  }

  .topLocation {
    margin-bottom: 50px;
    margin-top: 20px;
  }

  .topLocation .topLocationLists {
    margin-top: 30px;
  }

  .topLocation .topLocationLists:before, .topLocation .topLocationLists:after {
    content: "";
    display: table;
  }

  .topLocation .topLocationLists:after {
    clear: both;
  }

  .topLocation .topLocationLists ul {
    width: 20%;
    float: left;
  }

  .topLocation .topLocationLists ul li {
    padding-bottom: 5px;
  }

  .topLocation .topLocationLists ul a {
    font-size: 14px;
    font-size: 1.4rem;
  }

  .teaserList {
    padding: 25px 0;
  }

  .teaserList:before, .teaserList:after {
    content: "";
    display: table;
  }

  .teaserList:after {
    clear: both;
  }

  .teaserList .teaser2 {
    float: left;
    width: 20%;
  }

  .teaserList .teaser2 .img {
    margin-bottom: 10px;
  }

  .teaser2 {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 0 10px 10px 0;
  }

  .teaser2 .img img {
    width: 100%;
    height: auto;
  }

  .teaser2 .body h4 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .teaser2 .body h4 a {
    color: #1d1d1d;
  }

  .teaser2 .body p {
    margin: 0 0 0 0;
  }

  .teaser2 .body p a {
    font-weight: 600;
  }

  @media screen and (max-width: 955px) {
    .topLocation .topLocationLists ul {
      width: 25%;
    }

    .teaserList {
      padding: 25px 80px;
    }

    .teaserList .teaser2 {
      width: 100%;
      padding: 0 5px;
    }

    .teaserList .owl-next, .teaserList .owl-prev {
      background: url(https://content.instantoffices.com/Prod/images/legacyImages/myAccArr.png) no-repeat;
    }

    .teaserList .owl-prev {
      width: 28px;
      height: 49px;
      display: block;
      background-position: 0 0;
      position: absolute;
      top: 40%;
      margin-top: -25px;
      left: 10px;
    }

    .teaserList .owl-prev:hover {
      background-position: 0 -61px;
    }

    .teaserList .owl-next {
      width: 28px;
      height: 49px;
      display: block;
      position: absolute;
      top: 40%;
      margin-top: -25px;
      right: 10px;
      background-position: -47px 0;
    }

    .teaserList .owl-next:hover {
      background-position: -47px -61px;
    }
  }

  @media screen and (max-width: 722px) {
    .topLocation .topLocationLists ul {
      width: 50%;
    }

    .formLayer .campaignTitlesContainer {
      width: 100%;
      padding: 0 0 0 20px;
      text-align: center;
    }

    .formLayer .campaignTitlesContainer .mSearchTitle, .formLayer .campaignTitlesContainer .campaign .campaignTitle, .campaign .formLayer .campaignTitlesContainer .campaignTitle, .formLayer .campaignTitlesContainer .campaignBig .campaignTitle, .campaignBig .formLayer .campaignTitlesContainer .campaignTitle, .formLayer .campaignTitlesContainer .mSearchText, .formLayer .campaignTitlesContainer .campaign .campaignText, .campaign .formLayer .campaignTitlesContainer .campaignText, .formLayer .campaignTitlesContainer .campaignBig .campaignText, .campaignBig .formLayer .campaignTitlesContainer .campaignText {
      text-align: center;
    }

    .cityLandingFilterWrapper {
      position: relative;
    }

    .cityLandingFilterWrapper .container, .cityLandingFilterWrapper .contentModule, .cityLandingFilterWrapper .landingHeader .mainHeaderContact, .landingHeader .cityLandingFilterWrapper .mainHeaderContact, .cityLandingFilterWrapper .guidesContentModules .teasersList, .guidesContentModules .cityLandingFilterWrapper .teasersList {
      padding: 0;
    }

    .cityLandingFilters {
      position: initial;
      width: 100%;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 640px) {
    .topLocation .topLocationLists ul {
      width: 100%;
    }

    .teaserList {
      padding: 25px 40px;
    }

    .teaserList .owl-prev {
      left: 0;
    }

    .teaserList .owl-next {
      right: 0;
    }
  }

  @media screen and (max-width: 520px) {
    .teaserList .teaser2 {
      padding: 0;
    }
  }

  .ppcArticle {
    margin-top: 20px;
    width: 50%;
    padding-bottom: 60px;
    float: left;
    word-break: break-word;
  }

  .ppcFormContainer {
    position: relative;
    z-index: 5;
  }

  .ppcDescription {
    margin-top: 15px;
  }

  .ppcDescription p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px;
    line-height: 2rem;
    color: #1d1d1d;
    padding: 5px 0;
  }

  .ppcDescription ul {
    padding: 10px 0;
    list-style: disc inside;
  }

  .ppcDescription ul li {
    line-height: 1.6;
    display: list-item;
  }

  .ppcDescription h4 {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .mSearchPpc .mSearchTitle, .mSearchPpc .campaign .campaignTitle, .campaign .mSearchPpc .campaignTitle, .mSearchPpc .campaignBig .campaignTitle, .campaignBig .mSearchPpc .campaignTitle, .mSearchPpc .searchPPC {
    width: 40%;
  }

  .mSearchPpc .mSearchRow.double .mSearchSet {
    width: 100%;
  }

  .mSearchPpc .btn, .mSearchPpc .lButton, .mSearchPpc .dButton, .mSearchPpc .btnDelete, .mSearchPpc .scfSubmitButton {
    width: 50%;
  }

  .mSearchPpc .container, .mSearchPpc .contentModule, .mSearchPpc .landingHeader .mainHeaderContact, .landingHeader .mSearchPpc .mainHeaderContact, .mSearchPpc .guidesContentModules .teasersList, .guidesContentModules .mSearchPpc .teasersList {
    position: relative;
  }

  .mSearchPpc .selecter {
    z-index: 12;
  }

  @media screen and (max-width: 722px) {
    .mSearchPpc .mSearchTitle, .mSearchPpc .campaign .campaignTitle, .campaign .mSearchPpc .campaignTitle, .mSearchPpc .campaignBig .campaignTitle, .campaignBig .mSearchPpc .campaignTitle {
      width: 100%;
      text-align: center !important;
    }

    .mSearchPpc .searchPPC {
      padding: 0 20%;
      width: 100%;
    }

    .mSearchPpc .searchPPC .mainSearchSubmit {
      width: 100%;
    }

    .ppcArticle {
      padding: 0 10%;
      width: 100%;
    }
  }

  @media screen and (max-width: 640px) {
    .ppcArticle {
      padding: 0;
    }

    .mSearchPpc .searchPPC {
      padding: 0;
    }
  }

  .pageHeader .rCol .consultantWidgetWrapper {
    border-top: 1px solid #b5b5b5;
    border-bottom: 1px solid #b5b5b5;
    padding: 20px 15px;
    margin-top: 60px;
    float: right;
    width: 300px;
    width: 30rem;
  }

  .myAccountHeader {
    margin-left: 15px;
    padding: 0 0 20px;
  }

  @media screen and (max-width: 722px) {
    .myAccountHeader {
      margin-left: 0;
    }
  }

  .myAccWrap:before, .myAccWrap:after {
    content: "";
    display: table;
  }

  .myAccWrap:after {
    clear: both;
  }

  .myAccountSubHeader {
    max-width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    font-weight: 300;
  }

  @media screen and (max-width: 722px) {
    .myAccountSubHeader {
      max-width: 100%;
    }
  }

  .myAccountSubHeader div {
    font-weight: 300;
  }

  .myAccountSubHeader strong {
    font-weight: 600;
  }

  .myAccountSubHeader .myAccountHello {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .myAccountEnquiries {
    border-top: 1px solid #b5b5b5;
    background: #f4f4f4;
    padding: 30px 0;
  }

  .myAccountEnquiries .relatedWidgetImage {
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
  }

  .myAccountEnquiries .relatedWidgetImage .imgWrapper {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .myAccountEnquiries .relatedWidgetImage .imgWrapper span {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .myAccountEnquiries .relatedWidgetImage .imgWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }

  .myAccountEnquiries .relatedWidget {
    padding: 15px 0 0 0;
    background: transparent;
    border: 0 solid transparent;
  }

  .myAccountEnquiries .relatedWidget .relatedWidgetTitle {
    font-weight: 600;
    margin-left: 15px;
  }

  .myAccountEnquiries .relatedWidgetList {
    width: 82%;
    float: left;

    @media screen and (min-width: 956px) {
      display: block;
    }
  }

  .myAccountEnquiries .relatedWidgetList li {
    width: 25%;
    margin-right: 0;
    padding-right: 20px;
  }

  .myAccountEnquiries .blockHeader {
    padding-left: 15px;
  }

  .myAccountEnquiries .blockHeader h2 {
    margin-bottom: 20px;
  }

  .myAccountMore {
    height: 160px;
    padding: 10px;
    line-height: 140px;
    background: #d6d6d6;
    display: block;
    margin-top: 20px;
    font-size: 16px;
    font-size: 1.6rem;
    color: #1d1d1d !important;
    -webkit-transition: background 300ms ease-in-out;
    -o-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    text-align: center;
    width: 18%;
    float: right;
  }

  .myAccountMore:after {
    content: '';
    margin-left: 5px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/myAccountMore.png) 50% 50% no-repeat;
    width: 31px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
  }

  .myAccountMore:hover {
    background: #ccc;
  }

  .myAccountNoEnquiries {
    margin-left: 15px;
    margin-top: 20px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 25px;
    line-height: 2.5rem;
  }

  .myAccountNoEnquiries:empty {
    display: none;
  }

  @media screen and (max-width: 955px) {
    .myAccountEnquiries .relatedWidgetList {
      width: 75%;
      padding: 0 80px 0 40px;
    }

    .myAccountEnquiries .relatedWidgetList li {
      width: 50%;
      padding: 0 10px;
    }

    .myAccountEnquiries .relatedWidgetList .owl-item li {
      width: 100%;
    }

    .myAccountMore {
      width: 25%;
    }

    .myAccWidget .owl-next, .myAccWidget .owl-prev {
      background: url(https://content.instantoffices.com/Prod/images/legacyImages/myAccArr.png) no-repeat;
    }

    .myAccWidget .owl-prev {
      width: 28px;
      height: 49px;
      display: block;
      background-position: 0 0;
      position: absolute;
      top: 40%;
      margin-top: -25px;
      left: 10px;
    }

    .myAccWidget .owl-prev:hover {
      background-position: 0 -61px;
    }

    .myAccWidget .owl-next {
      width: 28px;
      height: 49px;
      display: block;
      position: absolute;
      top: 40%;
      margin-top: -25px;
      right: 50px;
      background-position: -47px 0;
    }

    .myAccWidget .owl-next:hover {
      background-position: -47px -61px;
    }
  }

  @media screen and (max-width: 660px) {
    .myAccountEnquiries .relatedWidgetList {
      width: 100%;
      padding: 0 40px;
    }

    .myAccountEnquiries .relatedWidgetList li {
      width: 100%;
      margin-bottom: 10px;
    }

    .myAccWidget .owl-next {
      right: 10px;
    }

    .myAccWidget .owl-prev {
      left: 10px;
    }

    .myAccountMore {
      width: auto;
      height: 70px;
      line-height: 70px;
      float: none;
      display: inline-block;
      padding: 0 50px;
      margin: 15px 0;
    }

    .myAccWrap {
      text-align: center;
    }

    .relatedWidgetItemTitle, .relatedWidgetItemDesc {
      text-align: left;
    }
  }

  .contactUs {
    padding: 40px 0px 0px 0px;
    padding: 4rem 0rem 0rem 0rem;
  }

  .contactUs .contentBoxWrapper {
    padding-right: 0px;
    padding-right: 0rem;
  }

  .contactUs .contentBox .header {
    min-height: 0;
  }

  .contactUs .contentBox .rCol {
    margin: 60px 0px 0px 0px;
    margin: 6rem 0rem 0rem 0rem;
  }

  .contactUs .contentBox h2 {
    margin: 0px 0px 10px 0px;
    margin: 0rem 0rem 1rem 0rem;
  }

  .contactUs .contentBox p {
    margin: 0px 0px 10px 0px;
    margin: 0rem 0rem 1rem 0rem;
  }

  .contactUsDesc {
    padding-bottom: 60px;
  }

  @media screen and (max-width: 955px) {
    .contactUsDesc .teasersList .teaser .teaserBlock .body {
      padding: 10px 10px 0 10px;
    }

    .contactUsDesc .teasersList .teaser .teaserBlock .body h3 {
      margin-bottom: 3px;
    }

    .contactUsDesc .teasersList .teaser .teaserBlock .body p {
      margin-bottom: 10px;
    }

    .contactUsDesc .teasersList .teaser .teaserBlock .body p.date {
      margin-bottom: 3px;
    }

    .contactTabsCtrl .container, .contactTabsCtrl .contentModule, .contactTabsCtrl .landingHeader .mainHeaderContact, .landingHeader .contactTabsCtrl .mainHeaderContact, .contactTabsCtrl .guidesContentModules .teasersList, .guidesContentModules .contactTabsCtrl .teasersList {
      padding: 0;
    }
  }

  @media screen and (max-width: 890px) {
    .contactUsDesc .teasersList .teaser {
      width: 100%;
    }

    .contactUsDesc .teasersList .teaser .teaserBlock {
      margin: 0;
    }
  }

  @media screen and (max-width: 660px) {
    .contactUsDesc {
      padding-bottom: 30px;
    }

    .contactUsDesc .teasersList .category {
      margin: 15px 0;
    }

    .contactUsDesc .teasersList .teaser {
      width: 49%;
      margin-right: 1%;
    }

    .contactUsDesc .teasersList .teaser:nth-child(odd) {
      margin-left: 1%;
      margin-right: 0;
    }

    .contactUsDesc .teasersList .teaser .teaserBlock .img {
      display: none;
    }

    .contactUsDesc .teasersList .teaser .teaserBlock .body {
      width: 100%;
    }
  }

  @media screen and (max-width: 420px) {
    .contactUsDesc .teasersList .teaser {
      width: 100%;
      margin: 0;
    }
  }

  .guideAbstract {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 27px;
    line-height: 2.7rem;
    font-weight: 300;
    color: #1d1d1d;
    margin-bottom: 20px;
  }

  .guideContent p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 23px;
    line-height: 2.3rem;
    font-weight: 300;
    color: #1d1d1d;
  }

  .guideContentModules .contentModule {
    margin-top: 25px;
  }

  .guideContentModules .mBottomSearch {
    margin-top: 25px;
  }

  .guideContentModules .teaser {
    margin-top: 25px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .guideContentModules .teasersList .teaser {
    padding: 0;
    margin: 20px 0 0 0;
    width: 100%;
    float: none;
    border: 1px solid #b5b5b5;
  }

  .guideContentModules .teasersList .teaser:after, .guideContentModules .teasersList .teaser:before {
    opacity: 0;
    border: 0 solid transparent;
    display: none;
  }

  .guideContentModules .teasersList .teaserRow {
    margin: 0;
  }

  .contentModuleLeftImage .leftImage {
    overflow: hidden;
    float: left;
    margin-left: -110px;
    padding: 0 30px 15px 0;
    font-size: 0;
    position: relative;
  }

  .contentModuleLeftImage .leftImage img {
    max-width: 100%;
    height: auto;
  }

  .contentModuleLeftImage .leftImage .imageCaption {
    padding: 15px;
    position: absolute;
    bottom: 15px;
    right: 30px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/graybg.png);
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px;
    line-height: 2rem;
  }

  .contentModuleRightImage .rightImage {
    overflow: hidden;
    float: right;
    margin-right: -110px;
    padding: 0 0 15px 30px;
    font-size: 0;
    position: relative;
  }

  .contentModuleRightImage .rightImage img {
    max-width: 100%;
    height: auto;
  }

  .contentModuleRightImage .rightImage .imageCaption {
    padding: 15px;
    position: absolute;
    bottom: 15px;
    left: 30px;
    right: 0;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/graybg.png);
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px;
    line-height: 2rem;
  }

  .contentBlockDesc h3 {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    color: #000;
    padding-bottom: 15px;
  }

  .contentModule .contentQuote {
    float: left;
    margin-left: -110px;
    padding-bottom: 15px;
    max-width: 235px;
    padding-right: 37px;
    position: relative;
  }

  .contentModule .contentQuote h2, .contentModule .contentQuote p h2 {
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 30px;
    line-height: 3rem;
    margin: 0px 0px 32px 0px;
    margin: 0rem 0rem 3.2rem 0rem;
    padding-top: 4px;
  }

  .contentModule .contentQuote h2:before, .contentModule .contentQuote p h2:before {
    display: block;
    margin-bottom: 10px;
  }

  .contentModule .contentQuote h3, .contentModule .contentQuote p h3 {
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 1.3;
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .contentModule .contentQuote h4, .contentModule .contentQuote p h4 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 200;
    line-height: 1.3;
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .contentModule .contentQuote h5, .contentModule .contentQuote p h5 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.3;
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .videoBlock iframe {
    width: 100%;
    height: 350px;
  }

  .guidesContentModules .contentModule {
    margin-top: 25px;
  }

  .guidesContentModules .contentModule h3 {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    color: #000;
    padding-bottom: 15px;
  }

  .guidesContentModules .teasersList {
    margin-top: 25px;
  }

  .contentBlockDesc h3:empty {
    display: none;
  }

  .nestedLinksList:before, .nestedLinksList:after {
    content: "";
    display: table;
  }

  .nestedLinksList:after {
    clear: both;
  }

  .nestedLinksList ul {
    width: 20%;
    float: left;
    padding-bottom: 0;
  }

  .nestedLinksList ul > li {
    margin-bottom: 0;
  }

  .nestedLinksList ul > li ul {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .nestedLinksList ul > li ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .nestedLinksList ul ul {
    padding: 5px 0 0 17px;
    margin-left: -5px;
    float: none;
    width: 100%;
  }

  .liniarLinksList ul {
    width: 50%;
    float: left;
    padding-right: 20px;
  }

  .liniarLinksList ul:nth-child(even) {
    padding-right: 0;
  }

  @media screen and (max-width: 1120px) {
    .guidesContentModules .teaser .teaserBlock .body {
      padding: 10px;
    }

    .guidesContentModules .teaser .teaserBlock .body h4 {
      padding-bottom: 5px;
    }

    .guidesContentModules .teaser .teaserBlock .body .onlineDownload {
      padding-top: 5px;
    }
  }

  @media screen and (max-width: 955px) {
    .contentModule .contentQuote, .contentModuleLeftImage .leftImage {
      margin-left: 0;
    }

    .contentModuleRightImage .rightImage {
      margin-right: 0;
    }

    .nestedLinksList > ul {
      width: 33%;
    }

    .guideContentModules .teasersList .teaserBlock {
      padding-bottom: 10px;
    }

    .guideContentModules .teasersList .teaserBlock .onlineDownload {
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  @media screen and (max-width: 720px) {
    .guideContentModules .rightImage, .guideContentModules .leftImage, .guideContentModules .contentQuote {
      width: 100%;
      max-width: 100% !important;
      padding: 0 0 20px 0 !important;
      text-align: center;
    }

    .guideContentModules .contentQuote h2 {
      margin-bottom: 25px;
    }
  }

  @media screen and (max-width: 660px) {
    .nestedLinksList > ul {
      width: 50%;
    }

    .liniarLinksList > ul {
      width: 100%;
      padding-bottom: 0;
    }

    .liniarLinksList > ul:nth-child(even) {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 440px) {
    .nestedLinksList > ul {
      width: 100%;
    }
  }

  .siteMapNav {
    padding: 0;
    margin: 15px 0 0;
  }

  .siteMapNav li {
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    margin-right: 10px;
  }

  .siteMapNav li a {
    font-size: 16px;
    font-size: 1.6rem;
  }

  .siteMapLinksLists {
    font-size: 0;
    margin-top: 40px;
    padding-bottom: 50px;
  }

  .siteMapLinksLists.googleBotList li {
    width: 20%;
    padding-right: 20px;
  }

  .siteMapLinksLists.googleBotList li .siteMapLinksList li {
    width: 100%;
    padding-right: 0;
  }

  .siteMapLinksLists li {
    width: 50%;
    padding-right: 60px;
    display: inline-block;
    vertical-align: top;
    *display: inline;
    *zoom: 1;
    margin-bottom: 20px;
  }

  .siteMapLinksLists li h2 {
    font-size: 20px;
    font-size: 2rem;
    color: #1d1d1d;
    margin-bottom: 15px;
    border-top: 1px solid #b5b5b5;
    padding-top: 15px;
  }

  .siteMapLinksList li {
    width: 100%;
    margin-bottom: 0;
    padding-right: 0;
  }

  .siteMapLinksList a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 21px;
    line-height: 2.1rem;
  }

  .siteMapSubTitle {
    font-size: 22px;
    font-size: 2.2rem;
    color: #1d1d1d;
  }

  .googleBotList li {
    width: 20%;
  }

  @media screen and (max-width: 960px) {
    .siteMapLinksLists.googleBotList li {
      width: 50%;
    }
  }

  @media screen and (max-width: 660px) {
    .siteMapNav li {
      padding-bottom: 20px;
    }

    .siteMapLinksLists li {
      width: 100%;
    }

    .siteMapLinksLists.googleBotList li {
      width: 100%;
    }
  }

  .howWeCanHelpHeader .abstract {
    font-size: 18px;
    font-size: 1.8rem;
    max-width: 480px;
    max-width: 48rem;
  }

  .howWeCanHelpHeader .lSide {
    float: left;
    width: 50%;
  }

  .howWeCanHelpHeader .rSide {
    float: left;
    width: 50%;
  }

  .howItWorks {
    padding: 40px 0px 40px 0px;
    padding: 4rem 0rem 4rem 0rem;
  }

  .howItWorks h2 {
    font-size: 30px;
    font-size: 3rem;
    font-weight: 300;
    float: left;
    width: 20%;
  }

  .howItWorks .howItWorksList {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 80%;
    position: relative;
  }

  .howItWorks .howItWorksList:before {
    border-top: 5px solid #b5d334;
    content: '';
    display: block;
    position: absolute;
    top: 32px;
    top: 3.2rem;
    left: 10%;
    right: 10%;
    z-index: 1;
  }

  .howItWorks .howItWorksItem {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 400;
    float: left;
    position: relative;
    text-align: center;
    width: 28%;
  }

  .howItWorks .howItWorksItem:last-child {
    width: 16%;
  }

  .howItWorks .howItWorksItem:last-child .howItWorksDesc {
    max-width: 100%;
  }

  .howItWorks .howItWorksItem:last-child:before {
    display: none;
  }

  .howItWorks .howItWorksContent {
    max-width: 140px;
    max-width: 14rem;
    text-align: center;
  }

  .howItWorks .howItWorksContent:before, .howItWorks .howItWorksContent:after {
    content: "";
    display: table;
  }

  .howItWorks .howItWorksContent:after {
    clear: both;
  }

  .howItWorks .howItWorksImg {
    background: #fff;
    width: 110px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 70px;
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  .howItWorks .howItWorksImg img {
    max-width: 100%;
    height: auto;
  }

  .howItWorks .howItWorksDesc {
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 0;
  }

  .howItWorks .howItWorksDesc:before {
    content: '';
  }

  .howItWorks .howItWorksDesc span {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 22px;
    line-height: 2.2rem;
    color: #1d1d1d;
    font-weight: 300;
  }

  @media screen and (max-width: 955px) {
    .howItWorks h2 {
      width: 100%;
      margin-bottom: 15px;
    }

    .howItWorks .howItWorksList {
      width: 100%;
    }
  }

  @media screen and (max-width: 660px) {
    .howItWorks {
      padding: 20px 0px 20px 0px;
      padding: 2rem 0rem 2rem 0rem;
    }

    .howItWorks .howItWorksList {
      text-align: left;
    }

    .howItWorks .howItWorksList:before {
      border-left: 5px solid #b5d334;
      border-top: 0 solid transparent;
      content: '';
      display: block;
      position: absolute;
      left: 35px;
      top: 10%;
      bottom: 10%;
      z-index: 1;
    }

    .howItWorks .howItWorksList .howItWorksContent {
      width: 100%;
      max-width: 100%;
      text-align: left;
    }

    .howItWorks .howItWorksList .howItWorksImg {
      width: 84px;
      padding: 0 10px 0 10px;
      float: left;
    }

    .howItWorks .howItWorksList .howItWorksDesc {
      padding-left: 96px;
      font-size: 16px;
      font-size: 1.6rem;
      height: 70px;
    }

    .howItWorks .howItWorksList .howItWorksDesc:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      *display: inline;
      *zoom: 1;
      height: 100%;
      margin-left: -15px;
    }

    .howItWorks .howItWorksList .howItWorksDesc span {
      font-size: 16px;
      font-size: 1.6rem;
      display: inline-block;
      vertical-align: middle;
      *display: inline;
      *zoom: 1;
      padding-left: 10px;
    }

    .howItWorks .howItWorksList .howItWorksItem {
      background: #fff;
      text-align: left;
      width: 100%;
      margin-bottom: 50px;
      position: relative;
      padding-bottom: 5px;
      padding-top: 5px;
      z-index: 2;
    }

    .howItWorks .howItWorksList .howItWorksItem:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 10px;
    }

    .howItWorks .howItWorksList .howItWorksItem:before {
      display: none;
    }
  }

  .boardMembers {
    margin: 0px 0px 48px 0px;
    margin: 0rem 0rem 4.8rem 0rem;
  }

  .boardMembers .peopleBox {
    width: 100%;
    padding-bottom: 15px;
    line-height: 1.5;
  }

  .boardMembers .peopleBox .content {
    border-style: solid;
    border-color: #b5b5b5;
    border-width: 1px;
    border-width: 0.1rem;
    padding: 14px;
    padding: 1.4rem;
  }

  .boardMembers .peopleBox .content:before, .boardMembers .peopleBox .content:after {
    content: "";
    display: table;
  }

  .boardMembers .peopleBox .content:after {
    clear: both;
  }

  .boardMembers .peopleBox .content h3 {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 200;
    margin: 0px 0px 3px 0px;
    margin: 0rem 0rem 0.3rem 0rem;
  }

  .boardMembers .peopleBox .content h4 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0px 0px 3px 0px;
    margin: 0rem 0rem 0.3rem 0rem;
  }

  .boardMembers .peopleBox .content .img {
    font-size: 0;
    float: left;
    line-height: 0;
    width: 41%;
  }

  .boardMembers .peopleBox .content .img img {
    font-size: 0;
    line-height: 0;
    width: 100%;
  }

  .boardMembers .peopleBox .content .img {
    width: 200px;
    width: 20rem;
  }

  .boardMembers .peopleBox .content .body {
    float: none;
    margin: 0px 0px 0px 200px;
    margin: 0rem 0rem 0rem 20rem;
    width: auto;
    padding: 0px 0px 0px 16px;
    padding: 0rem 0rem 0rem 1.6rem;
  }

  .boardMembers .peopleBox .content p {
    margin: 0;
  }

  @media screen and (max-width: 722px) {
    .boardMembers .peopleBox .img {
      float: left;
      margin-right: 15px;
      margin-bottom: 10px;
    }

    .boardMembers .peopleBox .body {
      margin: 0 !important;
      padding: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .boardMembers .peopleBox .img {
      width: 100% !important;
      text-align: center;
    }

    .mainHeaderContact .phone .phonesWrapper a {
      font-size: 1.8rem;
    }
  }

  .readMore {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    position: relative;
  }

  .readMore a:after {
    width: 7px;
    height: 11px;
    margin-top: -1px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/wra.png) no-repeat;
    content: '';
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    margin-left: 3px;
    -webkit-transition: margin 500ms ease-in-out;
    -o-transition: margin 500ms ease-in-out;
    transition: margin 500ms ease-in-out;
  }

  .readMore a:hover:after {
    margin-left: 10px;
  }

  .blackButtonGoTo {
    border: 0.1rem solid transparent;
    line-height: 1.2;
    text-align: center;
    padding: 5px 25px 5px 10px;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    font-weight: 700;
    z-index: 2;
  }

  div.cover, span.cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .pdf {
    margin: 0;
  }

  .pdf span:before {
    content: '';
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/pdf.png) no-repeat;
    height: 14px;
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    *display: inline;
    *zoom: 1;
    position: relative;
  }

  .img {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phone {
    color: #b5d334;
    font-size: 1.5rem;
    font-weight: bold;
    white-space: nowrap;
  }

  .phone a {
    color: #b5d334;
  }

  .campaign {
    position: relative;
  }

  .campaign.simple {
    min-height: 275px;
    min-height: 27.5rem;
    overflow: hidden;
  }

  .formSection.searchForms {
    margin: 0 auto;
    position: relative;
    max-width: 510px;
    max-width: 51rem;
    z-index: 2;
  }

  .basicCampaign {
    margin-top: -100px;
    margin-top: -10rem;
    margin-bottom: 0px;
    margin-bottom: 0rem;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50%;
    text-align: left;
    z-index: 2;
  }

  .searchForms, .basicCampaign {
    padding: 0 0 0 0;
    padding: 32px;
    padding: 3.2rem;
    text-align: center;
  }

  .searchForms.v1, .basicCampaign.v1 {
    text-align: left;
  }

  .searchForms h1, .basicCampaign h1 {
    font-size: 44px;
    font-size: 4.4rem;
    font-weight: 300;
    margin: 0px 0px 8px 0px;
    margin: 0rem 0rem 0.8rem 0rem;
  }

  .searchForms h2, .basicCampaign h2 {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0px 0px 24px 0px;
    margin: 0rem 0rem 2.4rem 0rem;
  }

  .searchForms fieldset, .basicCampaign fieldset {
    width: 520px;
    width: 52rem;
  }

  .searchForms fieldset:before, .searchForms fieldset:after, .basicCampaign fieldset:before, .basicCampaign fieldset:after {
    content: "";
    display: table;
  }

  .searchForms fieldset:after, .basicCampaign fieldset:after {
    clear: both;
  }

  .searchForms fieldset .row, .basicCampaign fieldset .row {
    margin: 0px 0px 15px 0px;
    margin: 0rem 0rem 1.5rem 0rem;
  }

  .contentQuote {
    position: relative;
  }

  .contentQuote h2 {
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 1.3;
    margin: 0px 0px 32px 0px;
    margin: 0rem 0rem 3.2rem 0rem;
    padding: 24px 0px 0px 0px;
    padding: 2.4rem 0rem 0rem 0rem;
  }

  .contentQuote h2:before {
    content: '';
    display: block;
    width: 18px;
    height: 15px;
    background: url(https://content.instantoffices.com/Prod/images/legacyImages/quote.png) 50% 50% no-repeat;
    margin: 0px 0px 16px 0px;
    margin: 0rem 0rem 1.6rem 0rem;
  }

  .contentQuote h3 {
    font-family: "bebas_neueregular", Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-size: 2.5rem;
    line-height: 1.3;
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .contentQuote h4 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 200;
    line-height: 1.3;
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .contentQuote h5 {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.3;
    margin: 0px 0px 5px 0px;
    margin: 0rem 0rem 0.5rem 0rem;
  }

  .contentHeader .contentQuote {
    float: none;
    margin: 0px 32px 16px 0px;
    margin: 0rem 3.2rem 1.6rem 0rem;
    max-width: none;
  }

  .contentHeader .lHeaderCol {
    float: left;
    padding: 0px 48px 0px 0px;
    padding: 0rem 4.8rem 0rem 0rem;
    width: 50%;
  }

  .contentHeader .rHeaderCol {
    float: left;
    padding: 0px 48px 0px 0px;
    padding: 0rem 4.8rem 0rem 0rem;
    width: 50%;
  }

  .relatedContent {
    border-top: 1px solid #f4f4f4;
    border-top-width: 1px;
    border-top-width: 0.1rem;
    margin: 20px 0px 0px 0px;
    margin: 2rem 0rem 0rem 0rem;
    padding: 20px 0px 0px 0px;
    padding: 2rem 0rem 0rem 0rem;
  }

  .relatedContent h2 {
    margin: 0 0 1rem 0;
    margin: 0px 0px 10px 0px;
    margin: 0rem 0rem 1rem 0rem;
  }

  .relatedContent ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .relatedContent ul h3 a {
    color: #1d1d1d;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .infoBlock img {
    max-width: 100%;
    height: auto;
  }

  .infoBlock, .mainContent .infoBlock {
    padding: 0px 0px 16px 0px;
    padding: 0rem 0rem 1.6rem 0rem;
  }

  .infoBlock h2, .mainContent .infoBlock h2 {
    margin-top: 0;
    padding-top: 0;
  }

  .infoBlock .lCol, .mainContent .infoBlock .lCol {
    float: left;
    padding: 48px 64px 0px 0px;
    padding: 4.8rem 6.4rem 0rem 0rem;
    width: 50%;
  }

  @media screen and (max-width: 955px) {
    .infoBlock .lCol, .mainContent .infoBlock .lCol {
      width: 100%;
      padding-right: 0;
    }
  }

  .infoBlock .rCol, .mainContent .infoBlock .rCol {
    float: right;
    padding: 48px 0px 0px 0px;
    padding: 4.8rem 0rem 0rem 0rem;
    width: 50%;
  }

  @media screen and (max-width: 955px) {
    .infoBlock .rCol, .mainContent .infoBlock .rCol {
      width: 100%;
      padding-right: 0 !important;
    }
  }

  .infoBlock .rCol h4, .mainContent .infoBlock .rCol h4 {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 200;
    margin: 0px 0px 24px 0px;
    margin: 0rem 0rem 2.4rem 0rem;
  }

  .infoBlock .rCol .btn, .infoBlock .rCol .lButton, .infoBlock .rCol .dButton, .infoBlock .rCol .btnDelete, .infoBlock .rCol .scfSubmitButton, .mainContent .infoBlock .rCol .btn, .mainContent .infoBlock .rCol .lButton, .mainContent .infoBlock .rCol .dButton, .mainContent .infoBlock .rCol .mButton, .mainContent .infoBlock .rCol .btnDelete, .mainContent .infoBlock .rCol .scfSubmitButton {
    max-width: none;
  }

  .infoBlock .btn, .infoBlock .lButton, .infoBlock .dButton, .infoBlock .btnDelete, .infoBlock .scfSubmitButton, .mainContent .infoBlock .btn, .mainContent .infoBlock .lButton, .mainContent .infoBlock .dButton, .mainContent .infoBlock .mButton, .mainContent .infoBlock .btnDelete, .mainContent .infoBlock .scfSubmitButton {
    display: block;
    max-width: 304px;
    max-width: 30.4rem;
    text-align: center;
  }

  .infoBlock h3, .mainContent .infoBlock h3 {
    font-size: 30px;
    font-size: 3rem;
    margin: 0px 0px 27px 0px;
    margin: 0rem 0rem 2.7rem 0rem;
    padding-top: 0;
  }

  .infoBlock .contentQuote h2, .mainContent .infoBlock .contentQuote h2 {
    margin: 0px 0px 32px 0px;
    margin: 0rem 0rem 3.2rem 0rem;
    padding: 0;
  }

  .infoBlock .contentQuote h3, .mainContent .infoBlock .contentQuote h3 {
    font-size: 25px;
    font-size: 2.5rem;
    margin: 0;
    padding: 0;
  }

  .infoBlock .contentQuote h4, .mainContent .infoBlock .contentQuote h4 {
    font-size: 14px;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
  }

  .infoBlock .lSide, .mainContent .infoBlock .lSide {
    float: left;
    padding: 0 2rem 0 0;
    padding: 0px 20px 0px 0px;
    padding: 0rem 2rem 0rem 0rem;
    width: 40%;
  }

  @media screen and (max-width: 955px) {
    .infoBlock .lSide, .mainContent .infoBlock .lSide {
      width: 50%;
      text-align: center;
    }
  }

  @media screen and (max-width: 440px) {
    .infoBlock .lSide, .mainContent .infoBlock .lSide {
      width: 100%;
    }
  }

  .infoBlock .rSide, .mainContent .infoBlock .rSide {
    float: left;
    width: 60%;
  }

  @media screen and (max-width: 955px) {
    .infoBlock .rSide, .mainContent .infoBlock .rSide {
      width: 50%;
      text-align: center;
    }
  }

  @media screen and (max-width: 440px) {
    .infoBlock .rSide, .mainContent .infoBlock .rSide {
      width: 100%;
    }
  }

  .infoBlock .rSide img, .mainContent .infoBlock .rSide img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 955px) {
    .infoBlock .rSide img, .mainContent .infoBlock .rSide img {
      margin-top: 45px;
    }
  }

  @media screen and (max-width: 440px) {
    .infoBlock .rSide img, .mainContent .infoBlock .rSide img {
      margin-top: 20px;
    }
  }

  .infoBlock img.cover, .mainContent .infoBlock img.cover {
    width: 100%;
  }

  .infoBlock.green {
    background: #edf2d6;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #cdcdcd;
  }

  .infoBlock.grey {
    background: #f4f4f4;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }

  .contentBlock {
    padding: 0px 0px 30px 0px;
    padding: 0rem 0rem 3rem 0rem;
  }

  .contentBlock h3 {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.5;
    margin: 32px 0px 20px 0px;
    margin: 3.2rem 0rem 2rem 0rem;
    padding: 0;
    margin-top: 0;
    padding-top: 32px;
    padding-top: 3.2rem;
  }

  /* ------------------------ */

  body.-cropped {
    overflow-y: hidden;
    padding-right: 18px;
  }

  body.-cropped .mSearchPpc .selecter {
    z-index: 4;
  }

  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: auto;
  }

  #lightbox {
    position: fixed;
    top: 20%;
    left: 50%;
    width: 90%;
    max-width: 850px;
    height: auto;
    margin-bottom: 10px;
    background: #fff;
    z-index: 1001;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    color: #000;
    position: absolute;
    font-size: 14px;
    line-height: normal;
  }

  #lightbox strong {
    font-weight: 600;
  }

  .dialog .closer {
    cursor: pointer;
    height: 19px;
    position: absolute;
    width: 19px;
    top: 3px;
    right: 3px;
    background: #b6d146;
    text-align: center;
    line-height: 17px;
  }

  .dialog .content .header {
    background: #f8f8f8;
    padding-top: 20px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-left: 40px;
  }

  .dialog .content .header span {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1;
    color: #181816;
    font-size: 1.75em;
    padding-right: 305px;
  }

  .dialog .content .header img {
    position: absolute;
    top: 15px;
    right: 40px;
    max-width: 40%;
  }

  .dialog .content .content-body {
    border-top: 1px solid #eee;
    padding-top: 20px;
    padding-right: 40px;
    padding-bottom: 55px;
    padding-left: 40px;
  }

  .dialog .content .content-body #secondaryCloser {
    background: #b6d146;
    padding: 10px 60px;
    right: 50% !important;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
  }

  @media screen and (max-width: 955px) {
    .dialog .content .header,
    .dialog .content .content-body {
      padding-left: 15px;
      padding-right: 15px;
    }

    .dialog .content .header img {
      max-width: 60%;
      display: block;
      float: none;
      position: static;
      top: auto;
      right: auto;
    }

    .dialog .content .header span {
      max-width: 100%;
      margin-bottom: 10px;
      display: block;
      float: none;
      font-size: 1.1em;
      padding-right: 0;
    }

    .dialog .content .content-body #secondaryCloser {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  /* ----- new enquiry form ------------- */

  .newEnquiryForm .scfSectionContent {
    width: 100%;
    margin-right: 0%;
    float: none;
  }

  .newEnquiryForm .enquirePageForm .selecter,
  .newEnquiryForm .enquirePageForm select,
  .newEnquiryForm .scfForm .selecter,
  .newEnquiryForm .scfForm select {
    max-width: 100%;
  }

  .newEnquiryForm .enquirePageForm select,
  .newEnquiryForm .scfForm select {
    height: 35px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 6px;
    margin-bottom: 4.5px;
  }

  .newEnquiryForm .scfForm .selecter-selected {
    padding-top: 7.5px;
    padding-left: 9.5px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 660px) {
    .newEnquiryForm .enquirePageForm select,
    .newEnquiryForm .scfForm select {
      height: 45px;
      padding-left: 8px;
    }
  }

  .newEnquiryForm .enquirePageForm input[type="text"],
  .newEnquiryForm .enquirePageForm input[type="email"],
  .newEnquiryForm .enquirePageForm input[type="tel"],
  .newEnquiryForm .enquirePageForm input[type="search"],
  .newEnquiryForm .enquirePageForm input[type="password"],
  .newEnquiryForm .scfForm input[type="text"],
  .newEnquiryForm .scfForm input[type="email"],
  .newEnquiryForm .scfForm input[type="tel"],
  .newEnquiryForm .scfForm input[type="search"],
  .newEnquiryForm .scfForm input[type="password"] {
    max-width: 100%;
  }

  .newEnquiryForm .enquirePageForm textarea,
  .newEnquiryForm .scfForm textarea {
    max-width: 100%;
    box-sizing: border-box;
  }

  .newEnquiryForm .scfSubmitButtonBorder {
    width: 100%;
  }

  .newEnquiryForm .scfForm .scfSubmitButtonBorder input {
    max-width: 100%;
  }

  .questioner__submit_disabled {
    background-color: grey;
    border-color: grey;
    cursor: default;
    pointer-events: none;
    opacity: .8;
  }

  .newEnquiryForm .scfForm .scfRequired {
    display: none;
  }

  .enquireForm_custom .formGroup {
    clear: both;
    display: block;
    padding-top: 10px;
    width: 100%;
  }

  .enquireForm_custom .blockFormHeader {
    border-top: 1px solid #b5b5b5;
    padding: 10px 15px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 35px;
    line-height: 3.5rem;
    color: #000;
    font-weight: 600;
  }

  .enquireForm_custom p {
    margin-top: 5px;
    margin: 0;
    font-size: 12px;
    line-height: 19px;
    padding: 0;
  }

  .enquireForm_custom .blockFormHeader h4 {
    font-weight: 600;
  }

  .enquireForm_custom .blockBody {
    background-color: transparent !important;
    margin: auto;
    padding: 3% 0%;
    display: inherit;
    overflow: hidden;
  }

  div.modal_background .enquireForm_custom .blockBody {
    width: 100%;
    padding: 0;
  }

  .enquireForm_custom .blockFooter {
    margin: auto;
    width: 95%;
    padding: 3%;
  }

  .enquireForm_custom .blockFooter p {
    line-height: 17px;
    font-size: 11px;
  }

  .enquireForm_custom .blockBody select {
    width: 100%;
    box-sizing: border-box;
    color: #c0c2b5;
    padding: 6px 6px;
    height: 37px;
  }

  .enquiryFormConfigurable .selecter select.validate + .selecter-selected {
    border: 2px solid #ca4242;
    background-color: #f5f5f5;
  }

  .enquiryFormConfigurable input[type="text"].input-validation-error,
  .enquiryFormConfigurable input[type="email"].input-validation-error,
  .enquiryFormConfigurable input[type="tel"].input-validation-error,
  .enquiryFormConfigurable input[type="search"].input-validation-error,
  .enquiryFormConfigurable input[type="password"].input-validation-error,
  .enquiryFormConfigurable select.input-validation-error,
  .enquiryFormConfigurable input[type="text"].validate,
  .enquiryFormConfigurable input[type="email"].validate,
  .enquiryFormConfigurable input[type="tel"].validate,
  .enquiryFormConfigurable input[type="search"].validate,
  .enquiryFormConfigurable input[type="password"].validate .enquiryFormConfigurable select.validate {
    border: 2px solid #ca4242;
    background-color: #f5f5f5;
  }

  .signInContainer input[type="text"].input-validation-error,
  .signInContainer input[type="password"].input-validation-error,
  .signInContainer input[type="text"].input-validation-error:focus,
  .signInContainer input[type="password"].input-validation-error:focus {
    border: 2px solid #ca4242 !important;
    background-color: #f5f5f5;
    box-shadow: none;
  }

  .enquireForm_custom .blockBody option {
    color: black;
  }

  .enquireForm_custom .selecter {
    width: 100%;
  }

  .enquireForm_custom .selecter {
    width: 100%;
  }

  .enquireForm_custom .selecter-selected {
    width: 100%;
    color: gray;
  }

  .enquireForm_custom input[type="button"], input[type="text"], textarea {
    width: 100%;
    margin: auto;
  }

  .enquireForm_custom input[type="button"] {
    color: #666 !important;
    background-color: #b5d334;
  }

  .enquireForm_custom .blockBody select:invalid {
    color: gray;
  }

  .enquireForm_custom .txtPhone {
    margin-top: 0px;
  }

  .garbage {
    display: none;
  }

  .garbage[style*=inline] + input,
  .garbage[style*=inline] + select,
  .garbage[style*=inline] + textarea {
    border: 2px solid #ca4242;
    background-color: #f5f5f5;
  }

  .showed {
    display: block;
  }

  .countryLandingSearch .selecter-options .selecter-item.selected {
    background-color: #b5d334;
  }

  .countryLandingSearch .selecter-options .selecter-item:hover {
    background-color: #eeeeee;
  }

  /* Contact us. Our company offices section */

  .contact-us-offices-country-block {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
  }

  .contact-us-office-block {
    width: 50%;
  }

  #enquireNow {
    padding-bottom: 20px;
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 660px) {
    .contact-us-office-block {
      width: 100%;
    }
  }

  .contact-us-offices-country-delimiter {
    height: 2px;
    width: 60px;
    background: #b5d334;
    margin-bottom: 30px;
  }

  .pageFormContainer .lButton {
    padding: 10px;
    width: auto;
    max-width: 100%;
    margin: 10px 0;
  }

  div.name\.hfAbTestIdentifierValue {
    display: none;
  }

  /*Enquiry Form Listing page*/
  @media screen and (max-width: 640px) {
    #enquireNowBorder,
    #enquireNow,
    #enquiryFormContainer {
      display: none;
    }
  }

  @media screen and (max-width: 660px) {
    .propPriceContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .propPriceTitle {
      flex-basis: 50%;
    }

    .propPriceValue {
      flex-basis: 50%;
      text-align: right;
      min-width: 156px;
    }

    .propItemPriceUnits {
      text-align: left;
    }

    .propPriceVirtual {
      flex-grow: 1;
      text-align: center;
      padding-top: 10px;
      border-top: 1px solid #4D4F53;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 360px) {
    .propPriceValue {
      min-width: 138px;
    }

    .propItemPrice {
      font-size: 3rem;
    }
  }

  .propItemPriceCol {
    padding-top: 50px;
  }

  @media (max-width: 660px) {
    .propItemPriceCol {
      position: relative;
    }

    .propPriceContainer {
      flex-direction: column;
      align-items: flex-start;
    }

    .propPriceContainer > * {
      text-align: left;
    }

    .propPriceContainer > * {
      width: 100%;
    }

    .propPriceValue {
      text-align: left;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    html {
      overflow-x: hidden;
    }
  }

  @media (max-width: 650px) {
    .quick-modal_io1178 input[name="email"], .quick-modal_io1178 input[name="phone"] {
      width: calc(~'100% - 50px');
      margin: 0;
      display: inline-block;
    }
  }

  @media (max-width: 650px) {
    .quick-modal_io1178 #officeSize .questioner-title__text {
      font-size: 25px !important;
    }
  }

  @media (max-width: 360px) {
    .quick-modal_io1178 #officeSize .questioner-radio {
      height: 30px !important;
    }
  }

  .quick-modal_io1178 .quick-modal__container {
    height: auto;
  }

  @media (orientation: landscape) and (max-height: 680px) {
    .quick-modal__container {
      margin: 0;
    }
  }

  .seo-container {
    background-color: #fff;
  }

  .seo {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
  }

  .seo__header {
    font-size: 1.4em;
    margin-bottom: 1.5em;
    padding-top: 2em;
  }

  .seo__text {
    padding-bottom: 1em;
    margin-top: 2em;
  }

  .office-types {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    flex-wrap: wrap;
  }

  .office-type {
    padding: 15px;
    margin-right: 10px;
    background-color: #FFF;
    border: 2px solid #b5d334;
    flex-grow: 1;
  }

  .office-type_link {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .office-type_image {
    width: 50px;
    margin-right: 20px;
  }

  .office-types_description {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    font-weight: 600;
  }

  .office-types_description__top {
    color: #b5d334;
  }

  .office-types_description__bottom {
    color: #808080;
  }

  @media all and (max-width: 1020px) {
    .office-type {
      width: 48%;
    }

    .office-type {
      margin-bottom: 10px;
    }

    .mobileWidget {
      display: none;
    }

    .map-bubble-container {
      width: 100%;
    }

    .mapImageContainer {
      max-width: 175px;
    }

    .mapInfoContainer {
      padding: 15px 20px;
    }

    .bubble {
      position: static !important;
    }
  }

  @media all and (max-width: 660px) {
    .office-types {
      flex-direction: column;
    }

    .office-type {
      margin-bottom: 10px;
      width: 100%;
    }

    .mapImageContainer {
      max-width: 115px;
    }

    .map-bubble-container {
      height: 120px;
    }

    .mapInfoContainer {
      padding: 10px 20px;
    }
  }

  .landingTopLocationTitle {
    margin-top: 50px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #b5d334;
    margin-bottom: 15px;
    color: #1d1d1d;
    display: inline-block;
    font-size: 3rem;
    font-weight: 300;
    padding: 0px 0px 10px;
    clear: both;
    overflow: hidden;
  }

  .superscript-text {
    position: relative;
    top: -5px;
  }

  @media all and (min-width: 1440px) {
    .map-bubble-container {
      width: 454px;
    }
  }
  /* Centre Page Start */

  .centrePage * {
    outline: none;
    font-family: Helvetica, Arial, sans-serif;
  }

  .centrePage .searchInfoHeader {
    border-bottom: 2px solid #f4f4f4;
    margin: 0 -40px;
    margin-bottom: 40px;
    width: calc(~'100% + 80px');
    padding: 20px 40px;
  }

  .centrePage .container {
    max-width: 100%;
    padding: 0 40px;
  }

  .centrePage .propItemPrice {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    color: #9d9d9d;
  }

  .centrePage .propPriceTitle * {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.29;
    color: #4d4f53;
  }

  .centrePage .propPriceWrap:last-child {
    margin-bottom: 20px;
    border-bottom: none;
  }

  .centrePage .propPriceContainer {
    padding-bottom: 0;
  }

  .centrePage .relatedWidget {
    margin: 0 -60px;
    padding: 60px;
    background-color: #fff;
    border-top: 2px solid #f4f4f4;
    display: flex;
    padding-bottom: 40px;
  }

  .centrePage .relatedWidgetTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;
    color: #1d1d1d;
    min-width: 20%;
  }

  .centrePage .relatedWidgetTitle span {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.56;
    color: #1d1d1d;
  }

  .centrePage .relatedWidgetList {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .centrePage .relatedWidgetList:after,
  .centrePage .relatedWidgetList:before {
    display: none;
  }

  .centrePage .relatedWidgetList li .relatedWidgetImage {
    width: 100%;
    height: 160px;
    padding: 0;
  }

  .centrePage .relatedWidgetList li {
    width: calc(~'100% * (1 / 3) - 40px');
    padding-right: 0;
  }

  .centrePage .relatedLinks li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    color: #808080;
  }

  .centrePage .relatedWidgetItemTitle a {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.29;
    color: #4d4f53;
  }

  .centrePage .relatedWidgetItemDesc {
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: normal;
    color: #808080;
  }

  .centrePage .breadcrumbs li a,
  .centrePage .breadcrumbs li {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #808080;
  }

  .centrePage .breadcrumbs li:before {
    content: ">";
    padding: 0 5px;
    font-weight: 800;
    color: #99ba14;
    font-size: 11px;
  }

  .centrePage .productImage {
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: 50%;
  }

  .centrePage .row {
    display: flex;
    width: 100%;
    align-items: center;
    height: 90px;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
  }

  .centrePage .space {
    margin-bottom: 20px;
  }

  .centrePage .description {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #808080;
  }

  @media screen and (max-width: 1279px) {
    .centrePage .container {
      padding: 0 40px;
    }

    .centrePage .relatedWidget {
      flex-direction: column;
      padding: 30px 60px;
    }

    .centrePage .relatedWidgetTitle span {
      margin-bottom: 30px;
      display: inline-block;
    }

    .centrePage .relatedWidgetImage img {
      object-fit: cover;
      height: 100%;
    }

    .centrePage .searchInfoHeader {
      margin: 0 -40px;
      width: calc(~'100% + 80px');
      padding: 20px 40px;
      margin-bottom: 40px;
    }

    .mainHeaderContact .phone a {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1019px) {
    .centrePage .relatedWidgetItemDesc {
      display: none;
    }

    .centrePage .searchInfoHeader {
      margin-bottom: 0;
      border-bottom: none;
      margin-top: 0;
    }

    .centrePage .productImage {
      margin: 0;
      height: 100%;
      object-fit: cover;
    }

    .centrePage .propPriceContainer {
      display: flex;
      flex-wrap: wrap;
    }

    .centrePage .relatedWidgetList li .relatedWidgetImage {
      height: 120px;
    }

    .centrePage .relatedWidgetList li {
      width: calc(~'100% * (1 / 3) - 20px');
    }

    .mainHeaderContact .phone a {
      display: inline-block;
      position: relative;
      right: 20px;
      width: auto;
      max-width: 100%;
      min-height: 26px;
      max-height: 100%;
      word-break: break-word;
      white-space: pre-wrap;
      border-bottom-left-radius: 13px;
      border-top-left-radius: 13px;
      background-color: #f4f4f4;
      font-size: 12px;
      font-weight: 700;
      font-family: Helvetica, Arial, sans-serif;
      text-decoration: none;
      color: #99ba14;
      padding: 6px 5px 6px 14px;
      cursor: pointer;
      height: 27px;
      line-height: 1.4;
    }

    .mainHeaderContact .phone a:before {
      content: "";
      width: 0;
      height: 0;
      right: -16px;
      top: 0;
      z-index: 10;
      position: absolute;
      border-top: 13px solid transparent;
      border-left: 16px solid #f4f4f4;
      border-bottom: 13px solid transparent;
    }

    .mainHeaderContact .phone svg {
      display: inline-block;
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 679px) {
    .centrePage .searchInfoHeader {
      margin: 0 -20px;
      width: calc(~'100% + 40px');
      padding: 20px;
    }

    .centrePage .container {
      padding: 0 20px;
    }

    .centrePage .relatedWidgetList li .relatedWidgetImage {
      width: 155px;
      height: 110px;
      margin-right: 20px;
      min-width: 155px;
    }

    .centrePage .relatedWidgetList.relatedLinks li {
      width: 50%;
      padding-right: 10px;
    }
  }

  @media screen and (width: 1020px) {
    .centrePage .searchInfoHeader {
      margin-top: 20px;
    }
  }

  @media (orientation: landscape) and (max-height: 768px) {
    .propPriceTitle {
      margin-bottom: 0;
    }
  }

  #master.slick .mainHeader {
    height: 55px;
    padding: 0;
    position: fixed;
    top: 0;
  }

  #master.slick .mainHeader .mainHeaderLogo {
    height: 54px;
    line-height: 54px;
    margin-left: 25px;
  }

  #master.slick .mainHeader .mainHeaderLogo img {
    height: 17px;
    width: auto;
  }

  #master.slick .mainHeader .mainHeaderContact {
    line-height: 48px;
    height: 100%;
  }
}

@media (min-width: 1920px) {
    .legacy-page .centrePage .searchInfoHeader {
        padding: 20px ~"calc((100vw - 1920px) / 2 + 40px)";
    }
}